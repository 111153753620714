import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useRecoilState } from "recoil";

import useIntersectionObserver from "@sellernote/_shared/src/hooks/common/useIntersectionObserver";
import { FORWARDING_AUTH_ATOMS } from "@sellernote/_shared/src/states/forwarding/auth";

import ExportationBanner from "../../../components/ExportationBanner";

import LoginModal from "../../LoginModal";
import DesktopHeader from "./DesktopHeader";
import MobileHeader from "./MobileHeader";
import Styled from "./index.styles";

export default function Header({
  isTransparentBackground,
  onSuccessLogin,
}: {
  isTransparentBackground?: boolean;
  onSuccessLogin?: () => void;
}) {
  const dispatch = useDispatch();

  const [loggedIn, setLoggedIn] = useRecoilState(
    FORWARDING_AUTH_ATOMS.USER_LOGGED_IN
  );

  const [isVisibleLoginModal, setIsVisibleLoginModal] = useState(false);

  const { target, isActiveTarget } = useIntersectionObserver({
    threshold: 0.99,
  });

  useEffect(() => {
    const accessToken =
      window.localStorage.getItem("accessToken") ||
      window.sessionStorage.getItem("accessToken");

    if (accessToken) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, [dispatch, setLoggedIn]);

  return (
    <>
      <div ref={target} />

      <Styled.container
        isTransparentBackground={isTransparentBackground}
        className={isActiveTarget ? "" : "is-scrolled"}
      >
        <ExportationBanner />

        <DesktopHeader
          loggedIn={!!loggedIn}
          setIsVisibleLoginModal={setIsVisibleLoginModal}
          isTransparentBackground={isTransparentBackground && isActiveTarget}
        />

        <MobileHeader
          loggedIn={!!loggedIn}
          setIsVisibleLoginModal={setIsVisibleLoginModal}
        />

        <LoginModal
          active={isVisibleLoginModal}
          setIsVisibleModal={setIsVisibleLoginModal}
          isClickedLoginButton
          afterLoginCallback={onSuccessLogin}
        />
      </Styled.container>
    </>
  );
}
