import SvgIconWrapper from "./SvgIconWrapper";

export default function EnvelopeFilledIcon(
  props: React.SVGAttributes<SVGElement>
) {
  return (
    <SvgIconWrapper {...props}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.5625 6C2.5957 6 1 7.5957 1 9.5625C1 10.6832 1.52695 11.7371 2.425 12.4125L18.575 24.525C19.4211 25.1559 20.5789 25.1559 21.425 24.525L37.575 12.4125C38.473 11.7371 39 10.6832 39 9.5625C39 7.5957 37.4043 6 35.4375 6H4.5625ZM1 14.3125V29.75C1 32.3699 3.13008 34.5 5.75 34.5H34.25C36.8699 34.5 39 32.3699 39 29.75V14.3125L22.85 26.425C21.1578 27.6941 18.8422 27.6941 17.15 26.425L1 14.3125Z"
          fill="currentColor"
        />
      </svg>
    </SvgIconWrapper>
  );
}
