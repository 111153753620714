import { APP_REGION } from "../../constants";
import { openChannelIOChatModal } from "./channelIO";
import { openZendeskChatModal } from "./zendesk";

/**
 * Chat Modal을 열기
 * - region에 맞는 적합한 챗 모달을 open
 */
export default function openChatModal() {
  if (!APP_REGION) return;

  if (APP_REGION === "KR") {
    openChannelIOChatModal();
    return;
  }

  if (APP_REGION === "SG") {
    openZendeskChatModal();
    return;
  }
}
