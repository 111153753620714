import { Trans } from "react-i18next";
import { useQueryClient } from "react-query";

import {
  MutationSideEffectType,
  useAppMutation,
  useAppQueryWithQueryKeyFactory,
} from "@sellernote/_shared/src/services/query";

import { ResponseFailureInfo } from "../../types/common/common";

import {
  ACCEPT_INVITED_TEAM_REQ,
  ACCEPT_INVITED_TEAM_REQ_PATH_PARAMS,
  ACCEPT_INVITED_TEAM_RES,
  ASSIGN_MASTER_REQ,
  ASSIGN_MASTER_RES,
  CHANGE_AUTHORITY_OF_MEMBER_REQ,
  CHANGE_AUTHORITY_OF_MEMBER_RES,
  CHECK_ALREADY_JOINED_MEMBER_REQ,
  CHECK_ALREADY_JOINED_MEMBER_RES,
  CREATE_TEAM_REQ,
  CREATE_TEAM_RES,
  DELETE_TEAM_ATTACHMENT_REQ,
  DELETE_TEAM_ATTACHMENT_RES,
  DELETE_TEAM_MEMBER_REQ,
  DELETE_TEAM_REQ,
  DELETE_TEAM_RES,
  GET_ACCOUNT_INFO_RES,
  GET_INVITED_TEAM_LIST_RES,
  GET_TEAM_INFO_RES,
  GET_TEAM_MEMBER_LIST_REQ,
  GET_TEAM_MEMBER_LIST_RES,
  INVITE_MEMBER_REQ,
  INVITE_MEMBER_RES,
  LEAVE_TEAM_RES,
  RESTORE_TEAM_REQ,
  RESTORE_TEAM_RES,
  SWITCH_ACCOUNT_REQ,
  SWITCH_ACCOUNT_REQ_PATH_PARAMS,
  SWITCH_ACCOUNT_RES,
  UPDATE_TEAM_INFO_REQ,
  UPDATE_TEAM_INFO_RES,
} from "../../api-interfaces/shipda-api/team";

export const TEAM_QUERY_KEY_GEN = {
  all: () => [{ scope: "forwarding/TEAM_QUERY" }] as const,

  teamInfo: () =>
    [{ ...TEAM_QUERY_KEY_GEN.all()[0], subScope: "teamInfo" }] as const,
  getTeamInfo: () =>
    [
      {
        ...TEAM_QUERY_KEY_GEN.teamInfo()[0],
      },
    ] as const,

  teamAttachment: () =>
    [{ ...TEAM_QUERY_KEY_GEN.all()[0], subScope: "teamAttachment" }] as const,
  getTeamAttachment: () =>
    [
      {
        ...TEAM_QUERY_KEY_GEN.teamAttachment()[0],
      },
    ] as const,

  accountInfo: () =>
    [{ ...TEAM_QUERY_KEY_GEN.all()[0], subScope: "accountInfo" }] as const,
  getAccountInfo: () =>
    [
      {
        ...TEAM_QUERY_KEY_GEN.accountInfo()[0],
      },
    ] as const,

  invitedTeamList: () =>
    [{ ...TEAM_QUERY_KEY_GEN.all()[0], subScope: "invitedTeamList" }] as const,
  getInvitedTeamList: () =>
    [
      {
        ...TEAM_QUERY_KEY_GEN.invitedTeamList()[0],
      },
    ] as const,

  teamMemberList: (params: GET_TEAM_MEMBER_LIST_REQ) =>
    [
      { ...TEAM_QUERY_KEY_GEN.all()[0], ...params, subScope: "teamMemberList" },
    ] as const,
  getTeamMemberList: (params: GET_TEAM_MEMBER_LIST_REQ) =>
    [
      {
        ...TEAM_QUERY_KEY_GEN.teamMemberList(params)[0],
        ...params,
      },
    ] as const,
};

function useDeleteTeamAttachment({ onSuccess }: { onSuccess?: () => void }) {
  const mutation = useAppMutation<
    DELETE_TEAM_ATTACHMENT_REQ,
    DELETE_TEAM_ATTACHMENT_RES
  >({
    requestOptions: {
      path: "file/delete",
      method: "delete",
      apiType: "ShipdaDefaultNew",
    },

    onSuccess,
  });

  return { ...mutation };
}

function useRestoreTeam({ onSuccess }: { onSuccess?: () => void }) {
  const mutation = useAppMutation<RESTORE_TEAM_REQ, RESTORE_TEAM_RES>({
    requestOptions: {
      path: "/team/restore",
      method: "patch",
      apiType: "ShipdaDefaultNew",
    },

    onSuccess,

    failureModalInfo: {
      customizeMessage: () => ({
        title: <Trans i18nKey="company-info:API_팀정보_복원_오류" />,
      }),
    },
  });

  return { ...mutation };
}

function useDeleteTeam({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: (failureInfo: ResponseFailureInfo | undefined) => void;
}) {
  const mutation = useAppMutation<DELETE_TEAM_REQ, DELETE_TEAM_RES>({
    requestOptions: {
      path: "/team",
      method: "delete",
      apiType: "ShipdaDefaultNew",
    },

    onSuccess,
    onError,

    failureModalInfo: {
      customizeMessage: (failureInfo) => {
        if (
          failureInfo?.code === 400 &&
          failureInfo?.error === "There are other members on the team"
        ) {
          return {
            messageType: "titleOnly",
            title: <Trans i18nKey="company-info:API_팀삭제_멤버_확인_오류" />,
          };
        }

        return {
          title: <Trans i18nKey="company-info:API_팀삭제_오류" />,
        };
      },
    },
  });

  return { ...mutation };
}

function useUpdateTeamInfo({ onSuccess }: { onSuccess?: () => void }) {
  const mutation = useAppMutation<UPDATE_TEAM_INFO_REQ, UPDATE_TEAM_INFO_RES>({
    requestOptions: {
      path: "/team",
      method: "patch",
      apiType: "ShipdaDefaultNew",
    },

    onSuccess,

    failureModalInfo: {
      customizeMessage: (failureInfo) => {
        if (
          failureInfo?.code === 400 &&
          failureInfo?.error === "Bad Request Exception"
        ) {
          return {
            messageType: "titleOnly",
            title: (
              <Trans i18nKey="company-info:API_팀정보_수정_팀명_글자_오류" />
            ),
          };
        }

        return {
          title: <Trans i18nKey="company-info:API_팀정보_수정_오류" />,
        };
      },
    },
  });

  return { ...mutation };
}

function useGetTeamInfo({
  enabled,
  onSuccess,
}: {
  enabled?: boolean;
  onSuccess?: (val: GET_TEAM_INFO_RES) => void;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof TEAM_QUERY_KEY_GEN.getTeamInfo>,
    GET_TEAM_INFO_RES
  >({
    queryKey: TEAM_QUERY_KEY_GEN.getTeamInfo(),
    requestOptions: {
      method: "get",
      path: "/team",
      apiType: "ShipdaDefaultNew",
    },

    onSuccess,

    ...(typeof enabled === "boolean" ? { enabled } : {}),

    failureModalInfo: {
      customizeMessage: () => ({
        title: <Trans i18nKey="company-info:API_팀정보_불러오기_오류" />,
      }),
    },
  });

  return { ...queryResult };
}

function useGetAccountInfo({
  enabled,
  hidesLoading,
}: {
  enabled?: boolean;
  hidesLoading?: boolean;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof TEAM_QUERY_KEY_GEN.getAccountInfo>,
    GET_ACCOUNT_INFO_RES
  >({
    queryKey: TEAM_QUERY_KEY_GEN.getAccountInfo(),
    requestOptions: {
      method: "get",
      path: "/team/list",
      apiType: "ShipdaDefaultNew",
    },

    ...(typeof enabled === "boolean" ? { enabled } : {}),
    hidesLoading,

    failureModalInfo: {
      customizeMessage: () => ({
        title: <Trans i18nKey="team:API_팀_리스트_불러오기_오류" />,
      }),
    },
  });

  return { ...queryResult };
}

function useCreateTeam(
  { onSuccess }: { onSuccess?: (res?: CREATE_TEAM_RES) => void },
  sideEffectOptions?: MutationSideEffectType<CREATE_TEAM_REQ, CREATE_TEAM_RES>
) {
  const mutation = useAppMutation<CREATE_TEAM_REQ, CREATE_TEAM_RES>({
    requestOptions: {
      method: "post",
      path: `/team`,
      apiType: "ShipdaDefaultNew",
    },
    ...sideEffectOptions,

    onSuccess,

    failureModalInfo: {
      customizeMessage: () => ({
        title: <Trans i18nKey="team:API_팀_생성_오류" />,
      }),
    },
  });

  return { ...mutation };
}

function useSwitchAccount(
  { onSuccess }: { onSuccess?: () => void },
  sideEffectOptions?: MutationSideEffectType<
    SWITCH_ACCOUNT_REQ,
    SWITCH_ACCOUNT_RES
  >
) {
  const queryClient = useQueryClient();

  const mutation = useAppMutation<
    SWITCH_ACCOUNT_REQ,
    SWITCH_ACCOUNT_RES,
    SWITCH_ACCOUNT_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "post",
      path: ({ teamId }) => `/auth/switch?teamId=${teamId}`,
      apiType: "ShipdaDefaultNew",
    },
    ...sideEffectOptions,

    onSuccess: (data) => {
      const { accessToken, isPrivate } = data;

      // refreshToken 은 user login 시 받은 것을 그대로 사용하고, accessToken 만 user 토큰을 team 토큰으로 교체
      if (window.localStorage.getItem("accessToken")) {
        window.localStorage.setItem("accessToken", accessToken);
      }
      if (window.sessionStorage.getItem("accessToken")) {
        window.sessionStorage.setItem("accessToken", accessToken);
      }

      /** 팀 변경 시, 전체 쿼리 리셋해서 데이터 재요청하도록 함 */
      queryClient.resetQueries();

      if (onSuccess) {
        onSuccess();
      }
    },

    failureModalInfo: {
      customizeMessage: (failureInfo) => {
        if (failureInfo?.code === 403)
          return {
            messageType: "titleOnly",
            title: <Trans i18nKey="team:API_팀_계정_변경_속하지않은_팀_오류" />,
          };

        return {
          title: <Trans i18nKey="team:API_팀_계정_변경_오류" />,
        };
      },
    },
  });

  return { ...mutation };
}

function useGetInvitedTeamList({
  enabled,
  onSuccess,
  onSettled,
}: {
  enabled?: boolean;
  onSuccess?: (res: GET_INVITED_TEAM_LIST_RES) => void;
  onSettled?: () => void;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof TEAM_QUERY_KEY_GEN.getInvitedTeamList>,
    GET_INVITED_TEAM_LIST_RES
  >({
    queryKey: TEAM_QUERY_KEY_GEN.getInvitedTeamList(),
    requestOptions: {
      method: "get",
      path: "/users/invitation/list",
      apiType: "ShipdaDefaultNew",
    },

    onSuccess,

    onSettled,

    ...(typeof enabled === "boolean" ? { enabled } : {}),
  });

  return { ...queryResult };
}

function useAcceptInvitedTeam(
  { onSuccess }: { onSuccess?: () => void },
  sideEffectOptions?: MutationSideEffectType<
    ACCEPT_INVITED_TEAM_REQ,
    ACCEPT_INVITED_TEAM_RES
  >
) {
  const mutation = useAppMutation<
    ACCEPT_INVITED_TEAM_REQ,
    ACCEPT_INVITED_TEAM_RES,
    ACCEPT_INVITED_TEAM_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "put",
      path: ({ teamId, isAccept }) =>
        `/team/invite?teamId=${teamId}&isAccept=${isAccept}`,
      apiType: "ShipdaDefaultNew",
    },
    ...sideEffectOptions,

    onSuccess,

    failureModalInfo: {
      customizeMessage: () => ({
        title: <Trans i18nKey="team:API_팀_수락_오류" />,
      }),
    },
  });

  return { ...mutation };
}

function useGetTeamMemberList({
  enabled,
  onSuccess,
  ...params
}: {
  enabled?: boolean;
  onSuccess?: (data: GET_TEAM_MEMBER_LIST_RES) => void;
} & GET_TEAM_MEMBER_LIST_REQ) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof TEAM_QUERY_KEY_GEN.getTeamMemberList>,
    GET_TEAM_MEMBER_LIST_REQ,
    GET_TEAM_MEMBER_LIST_RES
  >({
    queryKey: TEAM_QUERY_KEY_GEN.getTeamMemberList(params),
    requestOptions: {
      method: "get",
      path: "/team/memberList",
      apiType: "ShipdaDefaultNew",
      params,
    },

    ...(typeof enabled === "boolean" ? { enabled } : {}),

    onSuccess,

    failureModalInfo: {
      customizeMessage: () => ({
        title: <Trans i18nKey="team:API_팀_멤버_목록_불러오기_오류" />,
      }),
    },
  });

  return { ...queryResult };
}

function useInviteMember(
  { onSuccess }: { onSuccess?: () => void },
  sideEffectOptions?: MutationSideEffectType<
    INVITE_MEMBER_REQ,
    INVITE_MEMBER_RES
  >
) {
  const mutation = useAppMutation<INVITE_MEMBER_REQ, INVITE_MEMBER_RES>({
    requestOptions: {
      method: "post",
      path: `/team/invite`,
      apiType: "ShipdaDefaultNew",
    },
    ...sideEffectOptions,

    onSuccess,

    failureModalInfo: {
      customizeMessage: () => ({
        title: <Trans i18nKey="team:API_멤버_초대_오류" />,
      }),
    },
  });

  return { ...mutation };
}

function useAssignMaster(
  { onSuccess }: { onSuccess?: () => void },
  sideEffectOptions?: MutationSideEffectType<
    ASSIGN_MASTER_REQ,
    ASSIGN_MASTER_RES
  >
) {
  const mutation = useAppMutation<ASSIGN_MASTER_REQ, ASSIGN_MASTER_RES>({
    requestOptions: {
      method: "put",
      path: `/team/assignMaster`,
      apiType: "ShipdaDefaultNew",
    },
    ...sideEffectOptions,

    onSuccess,

    failureModalInfo: {
      customizeMessage: (failureInfo) => {
        if (failureInfo?.code === 403)
          return {
            messageType: "titleOnly",
            title: <Trans i18nKey="team:API_양도_마스터_오류" />,
          };

        return {
          title: <Trans i18nKey="team:API_양도_오류" />,
        };
      },
    },
  });

  return { ...mutation };
}

function useDeleteTeamMember(
  { onSuccess }: { onSuccess?: () => void },
  sideEffectOptions?: MutationSideEffectType<DELETE_TEAM_MEMBER_REQ, unknown>
) {
  const mutation = useAppMutation<DELETE_TEAM_MEMBER_REQ, unknown>({
    requestOptions: {
      method: "delete",
      path: "team/member",
      apiType: "ShipdaDefaultNew",
    },
    ...sideEffectOptions,

    onSuccess,

    failureModalInfo: {
      customizeMessage: (failureInfo) => {
        if (failureInfo?.code === 400) {
          if (failureInfo.error === "cannot delete authorized user")
            return {
              messageType: "titleOnly",
              title: (
                <Trans i18nKey="team:API_멤버_삭제_마스터_관리자_삭제불가_오류" />
              ),
            };

          if (failureInfo.error === "cannot delete yourself")
            return {
              messageType: "titleOnly",
              title: <Trans i18nKey="team:API_멤버_삭제_본인_오류" />,
            };
        }

        return {
          title: <Trans i18nKey="team:API_멤버_삭제_오류" />,
        };
      },
    },
  });

  return { ...mutation };
}

function useChangeAuthorityOfMember(
  { onSuccess }: { onSuccess?: () => void },
  sideEffectOptions?: MutationSideEffectType<
    CHANGE_AUTHORITY_OF_MEMBER_REQ,
    CHANGE_AUTHORITY_OF_MEMBER_RES
  >
) {
  const mutation = useAppMutation<
    CHANGE_AUTHORITY_OF_MEMBER_REQ,
    CHANGE_AUTHORITY_OF_MEMBER_RES
  >({
    requestOptions: {
      method: "put",
      path: "team/access",
      apiType: "ShipdaDefaultNew",
    },
    ...sideEffectOptions,

    onSuccess,

    failureModalInfo: {
      customizeMessage: (failureInfo) => {
        if (failureInfo?.code === 400) {
          if (failureInfo.error === "cannot change access level of master")
            return {
              messageType: "titleOnly",
              title: <Trans i18nKey="team:API_권한변경_마스터_권한_오류" />,
            };

          if (
            failureInfo.error ===
            "manager cannot change access level of manager"
          )
            return {
              messageType: "titleOnly",
              title: <Trans i18nKey="team:API_권한변경_매니저_권한_오류" />,
            };
        }

        if (failureInfo?.code === 404) {
          return {
            messageType: "titleOnly",
            title: <Trans i18nKey="team:API_권한변경_멤버_찾기불가_오류" />,
          };
        }

        return {
          title: <Trans i18nKey="team:API_권한변경_오류" />,
        };
      },
    },
  });

  return { ...mutation };
}

function useLeaveTeam(
  { onSuccess }: { onSuccess?: (data: LEAVE_TEAM_RES) => void },
  sideEffectOptions?: MutationSideEffectType<unknown, LEAVE_TEAM_RES>
) {
  const mutation = useAppMutation<unknown, LEAVE_TEAM_RES>({
    requestOptions: {
      method: "delete",
      path: "/team/leave",
      apiType: "ShipdaDefaultNew",
    },
    ...sideEffectOptions,

    onSuccess,

    failureModalInfo: {
      customizeMessage: (failureInfo) => {
        if (failureInfo?.code === 400) {
          return {
            messageType: "titleOnly",
            title: (
              <Trans i18nKey="company-info:API_팀탈퇴_마스터_탈퇴불가_오류" />
            ),
          };
        }

        return {
          title: <Trans i18nKey="company-info:API_팀탈퇴_오류" />,
        };
      },
    },
  });

  return { ...mutation };
}

function useCheckAlreadyJoinedMember(
  {
    onSuccess,
  }: {
    onSuccess?: (
      data: CHECK_ALREADY_JOINED_MEMBER_RES,
      req: CHECK_ALREADY_JOINED_MEMBER_REQ
    ) => void;
  },
  sideEffectOptions?: MutationSideEffectType<
    CHECK_ALREADY_JOINED_MEMBER_REQ,
    CHECK_ALREADY_JOINED_MEMBER_RES
  >
) {
  const mutation = useAppMutation<
    CHECK_ALREADY_JOINED_MEMBER_REQ,
    CHECK_ALREADY_JOINED_MEMBER_RES
  >({
    requestOptions: {
      method: "post",
      path: "/team/member",
      apiType: "ShipdaDefaultNew",
    },
    ...sideEffectOptions,

    onSuccess,

    failureModalInfo: {
      customizeMessage: (failureInfo) => {
        return {
          title: <Trans i18nKey="team:API_멤버_확인_오류" />,
        };
      },
    },
  });

  return { ...mutation };
}

const TEAM_QUERY = {
  useDeleteTeamAttachment,
  useRestoreTeam,
  useDeleteTeam,
  useUpdateTeamInfo,
  useGetTeamInfo,
  useGetAccountInfo,
  useCreateTeam,
  useSwitchAccount,
  useGetInvitedTeamList,
  useAcceptInvitedTeam,
  useGetTeamMemberList,
  useInviteMember,
  useAssignMaster,
  useDeleteTeamMember,
  useChangeAuthorityOfMember,
  useLeaveTeam,
  useCheckAlreadyJoinedMember,
};

export default TEAM_QUERY;
