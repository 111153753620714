import SvgIconWrapper from "./SvgIconWrapper";

export default function SubmenuCodeIcon(
  props: React.SVGAttributes<SVGElement>
) {
  return (
    <SvgIconWrapper {...props}>
      <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M26.6587 23.0231H6.22824C4.62206 23.0217 3.3202 21.7199 3.31846 20.1133V2.77356C3.31865 2.63814 3.32832 2.50309 3.34728 2.36902C1.91154 2.57216 0.843782 3.80031 0.842041 5.24998V22.5897C0.843782 24.1963 2.14564 25.4982 3.75183 25.4995H24.1823C25.6321 25.498 26.8601 24.4302 27.0632 22.9947C26.9293 23.0134 26.7941 23.0231 26.6587 23.0231Z"
          fill="#D4E1F4"
        />
        <path
          d="M27.092 5.24988V22.5896C27.0903 24.1962 25.7884 25.4981 24.1823 25.4994H3.75183C2.14564 25.4981 0.843782 24.1962 0.842041 22.5896V5.24988C0.843782 3.64369 2.14564 2.34183 3.75183 2.34009H24.1823C25.7884 2.34183 27.0903 3.64369 27.092 5.24988ZM26.2253 22.5896V9.27966H1.70879V22.5896C1.70995 23.7175 2.6239 24.6317 3.75183 24.6327H24.1823C25.3102 24.6317 26.2241 23.7175 26.2253 22.5896ZM26.2253 8.41292V5.24988C26.2241 4.12195 25.3102 3.20819 24.1823 3.20683H3.75183C2.6239 3.20819 1.70995 4.12195 1.70879 5.24988V8.41292H26.2253Z"
          fill="#475FB0"
        />
        <path
          d="M19.8199 16.0368L17.0154 13.6489C16.8328 13.5004 16.565 13.5251 16.4122 13.7043C16.2595 13.8836 16.2781 14.1522 16.4541 14.3089L18.8704 16.3666L16.4541 18.4248C16.3321 18.5238 16.2732 18.6811 16.3005 18.8359C16.3276 18.9908 16.4365 19.1187 16.5849 19.1702C16.7335 19.2218 16.8982 19.1892 17.0154 19.0847L19.8199 16.6967C19.9167 16.6143 19.9726 16.4939 19.9726 16.3666C19.9726 16.2397 19.9167 16.119 19.8199 16.0368Z"
          fill="#54C3D6"
        />
        <path
          d="M14.8108 11.8231C14.5767 11.7738 14.3471 11.9237 14.2977 12.1578L12.5499 20.4565C12.5006 20.6904 12.6503 20.9201 12.8844 20.9698C12.9138 20.9758 12.9438 20.9789 12.9742 20.9789C13.1789 20.9787 13.3557 20.8353 13.3981 20.6347L15.1457 12.3364C15.1947 12.1023 15.0449 11.8724 14.8108 11.8231Z"
          fill="#54C3D6"
        />
        <path
          d="M12.4563 7.2189H12.4608C13.2163 7.21851 13.8294 6.60753 13.8319 5.85165V5.84739C13.8311 5.09189 13.22 4.47936 12.4645 4.47608H12.4608C11.7034 4.47492 11.0883 5.08802 11.0872 5.84526C11.086 6.6027 11.6991 7.21755 12.4563 7.2189ZM12.4608 5.34282H12.462C12.7406 5.34321 12.9663 5.56957 12.9658 5.84816C12.9656 6.12676 12.7394 6.35235 12.4608 6.35215H12.4589C12.1803 6.35157 11.9547 6.12521 11.9551 5.84662C11.9556 5.56783 12.182 5.34243 12.4608 5.34282Z"
          fill="#54C3D6"
        />
        <path
          d="M7.96167 16.3666C7.96167 16.4935 8.01739 16.6143 8.11412 16.6967L10.9187 19.0847C11.1013 19.2331 11.3691 19.2085 11.5219 19.029C11.6746 18.8498 11.656 18.5815 11.4799 18.4248L9.06367 16.3666L11.4799 14.3089C11.656 14.1522 11.6746 13.8836 11.5219 13.7043C11.3691 13.5251 11.1013 13.5004 10.9187 13.6489L8.11412 16.0368C8.01739 16.1192 7.96167 16.2397 7.96167 16.3666Z"
          fill="#54C3D6"
        />
        <path
          d="M6.87525 5.83019C6.87408 6.58724 7.48545 7.20248 8.2423 7.20576H8.24656C9.00187 7.20499 9.61478 6.59421 9.61788 5.8389V5.83464C9.61768 5.07876 9.00651 4.46584 8.25101 4.46313H8.24656C7.49087 4.46333 6.87795 5.07469 6.87525 5.83019ZM7.89116 5.47672C7.98518 5.38231 8.11307 5.3293 8.24656 5.32988H8.2483C8.52709 5.33027 8.75268 5.55663 8.7521 5.83522C8.75152 6.11421 8.52516 6.3396 8.24656 6.33902H8.24521C8.0411 6.33863 7.8573 6.2152 7.77991 6.02637C7.70233 5.83755 7.74625 5.62028 7.89116 5.47672Z"
          fill="#54C3D6"
        />
        <path
          d="M4.03715 4.44959C3.27971 4.44708 2.66351 5.05922 2.66138 5.81684C2.65887 6.57428 3.27101 7.19029 4.02844 7.1928H4.0327C4.79052 7.19396 5.40556 6.58086 5.40672 5.82342C5.40788 5.06579 4.79478 4.45075 4.03715 4.44959ZM4.0327 6.32606H4.03115C3.75217 6.32548 3.52658 6.09912 3.52716 5.82033C3.52755 5.54154 3.75391 5.31576 4.0327 5.31634H4.03463C4.31362 5.31672 4.5392 5.54308 4.53882 5.82207C4.53824 6.10086 4.31168 6.32644 4.0327 6.32606Z"
          fill="#54C3D6"
        />
      </svg>
    </SvgIconWrapper>
  );
}
