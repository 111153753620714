import styled from "styled-components";

const container = styled.div`
  width: 100%;
`;

const mobileContactListContainer = styled.div`
  // 위아래 16px 패딩 값이 필요해 커스터마이징
  .custom-divide {
    margin: 16px 0px;
  }
`;

const invisibleDivide = styled.div`
  margin-top: 16px;
`;

export default {
  container,
  mobileContactListContainer,
  invisibleDivide,
};
