import { atom } from "recoil";

import {
  InvitedTeamInfo,
  MemberStatus,
  TeamAuthorityInfo,
  TeamAuthorityKind,
  TeamInfo,
} from "@sellernote/_shared/src/types/common/team";

const KEY_PREFIX = "_shared-for-shipda/forwarding/team/atoms";

const INVITED_TEAM_LIST = atom<InvitedTeamInfo[]>({
  key: `${KEY_PREFIX}/INVITED_TEAM_LIST`,
  default: [],
});

const MEMBER_LIST_PAGE = atom<number>({
  key: `${KEY_PREFIX}/MEMBER_LIST_PAGE`,
  default: 0,
});

const MEMBER_LIST_STATUS_FILTER = atom<MemberStatus[]>({
  key: `${KEY_PREFIX}/MEMBER_LIST_STATUS_FILTER`,
  default: [],
});

const MEMBER_LIST_PO_AUTHORITY_FILTER = atom<TeamAuthorityKind | undefined>({
  key: `${KEY_PREFIX}/MEMBER_LIST_PO_AUTHORITY_FILTER`,
  default: undefined,
});

const MEMBER_LIST_BID_AUTHORITY_FILTER = atom<TeamAuthorityKind | undefined>({
  key: `${KEY_PREFIX}/MEMBER_LIST_BID_AUTHORITY_FILTER`,
  default: undefined,
});

const MEMBER_LIST_FULFILLMENT_AUTHORITY_FILTER = atom<
  TeamAuthorityKind | undefined
>({
  key: `${KEY_PREFIX}/MEMBER_LIST_FULFILLMENT_AUTHORITY_FILTER`,
  default: undefined,
});

const TEAM_INFO_FORM = atom<TeamInfo | undefined>({
  key: `${KEY_PREFIX}/TEAM_INFO_FORM`,
  default: undefined,
});

const CHANGE_AUTHORITY_FORM = atom<TeamAuthorityInfo | undefined>({
  key: `${KEY_PREFIX}/CHANGE_AUTHORITY_FORM`,
  default: undefined,
});

const IS_LEAVE_TEAM_SUCCESS = atom<boolean>({
  key: `${KEY_PREFIX}/IS_LEAVE_TEAM_SUCCESS`,
  default: false,
});

export default {
  INVITED_TEAM_LIST,
  MEMBER_LIST_PAGE,
  MEMBER_LIST_STATUS_FILTER,
  MEMBER_LIST_PO_AUTHORITY_FILTER,
  MEMBER_LIST_BID_AUTHORITY_FILTER,
  MEMBER_LIST_FULFILLMENT_AUTHORITY_FILTER,
  TEAM_INFO_FORM,
  CHANGE_AUTHORITY_FORM,
  IS_LEAVE_TEAM_SUCCESS,
};
