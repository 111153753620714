import { ParsedUrlQueryInput } from "querystring";

import { useEffect } from "react";
import { useRouter } from "next/router";

import { APP_REGION } from "@sellernote/_shared/src/constants";
import { openZendeskChatModal } from "@sellernote/_shared/src/services/chat/zendesk";

/**
 * Chat modal을 open해야하는지 querystring을 확인 & open
 * (KR에서는 채널톡에서 제공하는 전용 URL을 사용하므로 이 기능은 사용되지 않는다)
 *
 * <사용사례>
 * - SG의 경우  Zendesk에서 (채널톡에서 제공하는 것과 달리) Chat용 url페이지를 제공하는 기능이 좋지 않아, 쉽다 사이트에서 채팅 모달을 오픈하는 방식으로 처리
 */
export default function useCheckNeedToOpenChatModal() {
  const router = useRouter();

  const QUERY_KEY_TO_CHECK_OPEN_CHAT = "openChat";

  useEffect(() => {
    if (APP_REGION !== "SG") return;

    if (router.query[QUERY_KEY_TO_CHECK_OPEN_CHAT] === "yes") {
      /**
       * 채널톡에서의 사용성은 검증되지 않아 `openChatModal`이 아닌 `openZendeskChatModal`을 호출
       * - 채널톡을 사용하는 KR에서는 사용하지도 않으므로 굳이 검증하지 않았음
       * - 간단히 체크해본 결과 채널톡에서는 초기화 순서관련하여 오류가 나는 듯 하니, 채널톡에서도 써야할 때는 정상작동 확인후 사용해야함
       */
      openZendeskChatModal();

      const newQuery: ParsedUrlQueryInput = {
        ...router.query,
      };
      // 사용한 querystring 정리
      delete newQuery[QUERY_KEY_TO_CHECK_OPEN_CHAT];
      router.replace(
        {
          query: newQuery,
        },
        undefined,
        {
          shallow: true,
        }
      );
    }
  }, [router]);
}
