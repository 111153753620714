import styled from "styled-components";

import { COLOR } from "@sellernote/_sds-v2/src/styles/colors";

const exportationBanner = styled.div`
  .custom-alert-banner {
    background-color: ${COLOR.primary_600};
  }
`;

export default {
  exportationBanner,
};
