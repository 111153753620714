import SvgIconWrapper from "./SvgIconWrapper";

export default function SubmenuFlightIcon(
  props: React.SVGAttributes<SVGElement>
) {
  return (
    <SvgIconWrapper {...props}>
      <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.07812 10.445V9.73438H2.29688V7.12102L1.86102 6.45312H0.820312V9.73438H0.601562V11.4959L1.4443 12.1237C3.32673 13.5183 5.60714 14.2719 7.94992 14.2734H9.42648C7.0837 14.2719 4.80329 13.5183 2.92086 12.1237C2.65934 11.9288 2.44695 11.6756 2.30064 11.3842C2.15432 11.0927 2.07813 10.7711 2.07812 10.445Z"
          fill="#D4E1F4"
        />
        <path
          d="M6.5957 10.5656C6.50868 10.5656 6.42522 10.6001 6.36368 10.6617C6.30215 10.7232 6.26758 10.8067 6.26758 10.8937V12.0522C6.26758 12.1392 6.30215 12.2227 6.36368 12.2842C6.42522 12.3457 6.50868 12.3803 6.5957 12.3803H7.7542C7.84123 12.3803 7.92469 12.3457 7.98622 12.2842C8.04776 12.2227 8.08233 12.1392 8.08233 12.0522V10.8937C8.08233 10.8067 8.04776 10.7232 7.98622 10.6617C7.92469 10.6001 7.84123 10.5656 7.7542 10.5656H6.5957ZM7.42608 11.7241H6.92383V11.2218H7.42608V11.7241Z"
          fill="#475FB0"
        />
        <path
          d="M10.6006 10.8937C10.6006 10.8067 10.5661 10.7232 10.5045 10.6617C10.443 10.6001 10.3595 10.5656 10.2725 10.5656H9.11401C9.02699 10.5656 8.94353 10.6001 8.88199 10.6617C8.82046 10.7232 8.78589 10.8067 8.78589 10.8937V12.0522C8.78589 12.1392 8.82046 12.2227 8.88199 12.2842C8.94353 12.3457 9.02699 12.3803 9.11401 12.3803H10.2725C10.3595 12.3803 10.443 12.3457 10.5045 12.2842C10.5661 12.2227 10.6006 12.1392 10.6006 12.0522V10.8937ZM9.94439 11.7241H9.44214V11.2218H9.94439V11.7241Z"
          fill="#475FB0"
        />
        <path
          d="M4.07715 10.5656C3.99012 10.5656 3.90666 10.6001 3.84513 10.6617C3.78359 10.7232 3.74902 10.8067 3.74902 10.8937V12.0522C3.74902 12.1392 3.78359 12.2227 3.84513 12.2842C3.90666 12.3457 3.99012 12.3803 4.07715 12.3803H5.23565C5.32267 12.3803 5.40613 12.3457 5.46767 12.2842C5.5292 12.2227 5.56377 12.1392 5.56377 12.0522V10.8937C5.56377 10.8067 5.5292 10.7232 5.46767 10.6617C5.40613 10.6001 5.32267 10.5656 5.23565 10.5656H4.07715ZM4.90752 11.7241H4.40527V11.2218H4.90752V11.7241Z"
          fill="#475FB0"
        />
        <path
          d="M14.1511 10.5656C14.0641 10.5656 13.9806 10.6001 13.9191 10.6617C13.8576 10.7232 13.823 10.8067 13.823 10.8937V12.0522C13.823 12.1392 13.8576 12.2227 13.9191 12.2842C13.9806 12.3457 14.0641 12.3803 14.1511 12.3803H15.3096C15.3966 12.3803 15.4801 12.3457 15.5416 12.2842C15.6032 12.2227 15.6377 12.1392 15.6377 12.0522V10.8937C15.6377 10.8067 15.6032 10.7232 15.5416 10.6617C15.4801 10.6001 15.3966 10.5656 15.3096 10.5656H14.1511ZM14.9815 11.7241H14.4792V11.2218H14.9815V11.7241Z"
          fill="#475FB0"
        />
        <path
          d="M16.6697 10.5656C16.5827 10.5656 16.4992 10.6001 16.4377 10.6617C16.3761 10.7232 16.3416 10.8067 16.3416 10.8937V12.0522C16.3416 12.1392 16.3761 12.2227 16.4377 12.2842C16.4992 12.3457 16.5827 12.3803 16.6697 12.3803H17.8282C17.9153 12.3803 17.9987 12.3457 18.0603 12.2842C18.1218 12.2227 18.1564 12.1392 18.1564 12.0522V10.8937C18.1564 10.8067 18.1218 10.7232 18.0603 10.6617C17.9987 10.6001 17.9153 10.5656 17.8282 10.5656H16.6697ZM17.5001 11.7242H16.9978V11.2218H17.5001V11.7242Z"
          fill="#475FB0"
        />
        <path
          d="M13.1195 12.0523V10.8937C13.1195 10.8067 13.0849 10.7232 13.0234 10.6617C12.9618 10.6001 12.8784 10.5656 12.7913 10.5656H11.6326C11.5455 10.5656 11.4621 10.6001 11.4005 10.6617C11.339 10.7232 11.3044 10.8067 11.3044 10.8937V12.0522C11.3044 12.1392 11.339 12.2227 11.4005 12.2842C11.4621 12.3457 11.5455 12.3803 11.6326 12.3803H12.7913C12.8783 12.3803 12.9618 12.3457 13.0233 12.2842C13.0849 12.2227 13.1194 12.1393 13.1195 12.0523ZM12.4632 11.7242H11.961V11.2218H12.4632V11.7242Z"
          fill="#475FB0"
        />
        <path
          d="M22.865 12.3804C22.9521 12.3804 23.0355 12.3458 23.0971 12.2843C23.1586 12.2228 23.1932 12.1393 23.1932 12.0523V10.8937C23.1932 10.8067 23.1586 10.7232 23.0971 10.6617C23.0355 10.6001 22.9521 10.5656 22.865 10.5656H21.7065C21.6195 10.5656 21.5361 10.6001 21.4745 10.6617C21.413 10.7232 21.3784 10.8067 21.3784 10.8937V12.0522C21.3784 12.1392 21.413 12.2227 21.4745 12.2842C21.5361 12.3457 21.6195 12.3803 21.7065 12.3803L22.865 12.3804ZM22.0347 11.2218H22.5369V11.7241H22.0347V11.2218Z"
          fill="#475FB0"
        />
        <path
          d="M20.3465 12.3804C20.4336 12.3804 20.517 12.3458 20.5786 12.2843C20.6401 12.2228 20.6747 12.1393 20.6747 12.0523V10.8937C20.6747 10.8067 20.6401 10.7232 20.5786 10.6617C20.517 10.6001 20.4336 10.5656 20.3465 10.5656H19.188C19.101 10.5656 19.0175 10.6001 18.956 10.6617C18.8944 10.7232 18.8599 10.8067 18.8599 10.8937V12.0522C18.8599 12.1392 18.8944 12.2227 18.956 12.2842C19.0175 12.3457 19.101 12.3803 19.188 12.3803L20.3465 12.3804ZM19.5161 11.2218H20.0184V11.7241H19.5161V11.2218Z"
          fill="#475FB0"
        />
        <path
          d="M11.6157 15.338C11.613 15.4123 11.6324 15.4856 11.6717 15.5488C11.7109 15.6119 11.7681 15.6618 11.8359 15.6922C11.9037 15.7225 11.9791 15.7319 12.0523 15.7191C12.1255 15.7064 12.1932 15.672 12.2467 15.6204C12.3002 15.5689 12.3371 15.5025 12.3526 15.4298C12.3682 15.3572 12.3616 15.2815 12.3338 15.2126C12.306 15.1437 12.2583 15.0847 12.1967 15.0431C12.1351 15.0015 12.0625 14.9793 11.9882 14.9793C11.8913 14.9777 11.7977 15.0145 11.7279 15.0817C11.6581 15.149 11.6178 15.2411 11.6157 15.338Z"
          fill="#475FB0"
        />
        <path
          d="M7.56323 15.3687C7.56323 15.4557 7.5978 15.5391 7.65934 15.6007C7.72087 15.6622 7.80433 15.6968 7.89136 15.6968H10.9429C11.0299 15.6968 11.1134 15.6622 11.1749 15.6007C11.2365 15.5391 11.271 15.4557 11.271 15.3687C11.271 15.2816 11.2365 15.1982 11.1749 15.1366C11.1134 15.0751 11.0299 15.0405 10.9429 15.0405H7.89136C7.80433 15.0405 7.72087 15.0751 7.65934 15.1366C7.5978 15.1982 7.56323 15.2816 7.56323 15.3687Z"
          fill="#475FB0"
        />
        <path
          d="M13.379 13.9368H11.9133C11.8263 13.9368 11.7428 13.9713 11.6813 14.0329C11.6198 14.0944 11.5852 14.1779 11.5852 14.2649C11.5852 14.3519 11.6198 14.4354 11.6813 14.4969C11.7428 14.5584 11.8263 14.593 11.9133 14.593H13.379V13.9368Z"
          fill="#475FB0"
        />
        <path
          d="M0.406383 11.7598L1.24857 12.3832C3.18807 13.8185 5.53713 14.5931 7.94992 14.5931H9.61614C9.70317 14.5931 9.78662 14.5585 9.84816 14.497C9.9097 14.4355 9.94427 14.352 9.94427 14.265C9.94427 14.178 9.9097 14.0945 9.84816 14.033C9.78662 13.9714 9.70317 13.9369 9.61614 13.9369H7.95014C5.67872 13.9318 3.46805 13.2028 1.6392 11.8557L0.929688 11.3306V10.0455H23.0641C23.3432 10.0456 23.6218 10.0692 23.897 10.1159V11.0929C23.8974 11.4342 24.0331 11.7614 24.2745 12.0027C24.5158 12.244 24.843 12.3798 25.1842 12.3802H27.0671C27.0683 12.4002 27.0697 12.4202 27.0701 12.4403C27.0844 13.2631 26.3879 13.9365 25.565 13.9365H18.5958V14.5928H25.5575C26.7482 14.5928 27.7473 13.6184 27.7262 12.4279C27.721 12.0724 27.6261 11.7239 27.4503 11.4148C27.2745 11.1057 27.0235 10.8459 26.7206 10.6597L26.037 10.2359C25.1441 9.68244 24.1144 9.38919 23.0639 9.3892H4.05907L2.14036 6.26879C2.11099 6.22103 2.06987 6.1816 2.02093 6.15425C1.97199 6.12689 1.91686 6.11254 1.8608 6.11255H0.803086C0.716062 6.11255 0.632602 6.14712 0.571066 6.20865C0.509531 6.27019 0.474961 6.35365 0.474961 6.44067V9.41485C0.41526 9.43982 0.364274 9.48188 0.328417 9.53575C0.29256 9.58962 0.273431 9.65289 0.273438 9.7176V11.496C0.27343 11.5473 0.285453 11.5979 0.308542 11.6437C0.33163 11.6895 0.365141 11.7293 0.406383 11.7598ZM26.3749 11.2177C26.5787 11.3436 26.7486 11.5175 26.8698 11.7242H25.1842C25.017 11.724 24.8566 11.6575 24.7383 11.5392C24.62 11.4209 24.5535 11.2605 24.5533 11.0932V10.2739C24.9529 10.3987 25.3352 10.5735 25.6912 10.7939L26.3749 11.2177ZM1.67738 6.7688L3.28869 9.38948H1.13121V6.7688H1.67738Z"
          fill="#475FB0"
        />
        <path
          d="M11.7622 21.8874H13.2567C13.3083 21.8874 13.3592 21.8753 13.4052 21.8519C13.4512 21.8286 13.4911 21.7947 13.5215 21.7531L19.4243 13.6889C19.4602 13.6399 19.4818 13.5818 19.4866 13.5212C19.4914 13.4606 19.4793 13.3998 19.4517 13.3457C19.424 13.2916 19.3818 13.2462 19.3298 13.2147C19.2779 13.1831 19.2181 13.1666 19.1574 13.167L13.5394 13.2035C13.4651 13.204 13.3931 13.2297 13.3353 13.2763C13.2775 13.323 13.2373 13.388 13.2212 13.4605L11.4419 21.4883C11.4312 21.5363 11.4315 21.586 11.4426 21.6338C11.4538 21.6817 11.4755 21.7264 11.5063 21.7647C11.537 21.8031 11.576 21.834 11.6203 21.8552C11.6646 21.8765 11.7131 21.8875 11.7622 21.8875V21.8874ZM13.8053 13.8578L18.5096 13.8273L13.09 21.231H12.171L13.8053 13.8578Z"
          fill="#54C3D6"
        />
      </svg>
    </SvgIconWrapper>
  );
}
