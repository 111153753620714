import styled, { css } from "styled-components";

import { mobile } from "@sellernote/_shared/src/styles/functions";
import { COLOR, TEXT_COLOR } from "@sellernote/_sds-v2/src/styles/colors";
import { setElevationStyle } from "@sellernote/_sds-v2/src/styles/elevation";
import { setFontStyle } from "@sellernote/_sds-v2/src/styles/typography";

const mobileHeader = styled.section`
  display: none;

  ${mobile(css`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    background-color: ${COLOR.wh};
    padding: 0 14.5px 0 16px;
    z-index: 1;
    ${setElevationStyle("level2")};
  `)}
`;

const authAndMenuContainer = styled.div<{ loggedIn: boolean }>`
  display: flex;
  align-items: center;

  .auth-container {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .user-name {
    margin-left: 6px;
    ${setFontStyle("Body3")};

    strong {
      margin-right: 4px;
      ${setFontStyle("SubHead3")};
    }
  }

  .menu-icon {
    margin-left: ${({ loggedIn }) => (loggedIn ? "12px" : "20px")};
    cursor: pointer;
  }
`;

const mobileDrawerContainer = styled.header`
  display: none;

  ${mobile(css`
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.25);
  `)}

  > .build-info {
    position: absolute;
    top: 4px;
    left: 4px;
    z-index: 9;
    color: gray;
  }
`;

const mobileAuthAndMenuContainer = styled.div`
  position: absolute;
  background-color: ${COLOR.wh};
  top: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  opacity: 1;
  padding: 80px 16px 140px 16px;
  overflow-y: scroll;

  > .auth-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;

    > .button:not(:last-child) {
      margin-right: 16px;
    }
  }

  .x-mark-default-icon {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }

  .login {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    line-height: 24px;
    width: 80px;
    height: 40px;
    border-radius: 4px;
    color: ${COLOR.wh};
    background-color: ${COLOR.primary_600};
    cursor: pointer;
  }
`;

const mobileMenu = styled.li`
  cursor: pointer;

  > .label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 16px;
    color: ${TEXT_COLOR.black_1};
    ${setFontStyle("SubHead2")};

    > .icon {
      margin-left: 10px;
    }

    > a,
    > a:visited {
      display: block;
      width: 100%;
      padding: 16px 16px;
      color: ${COLOR.grayScale_800};
    }
  }

  > .link-label {
    padding: 0;
  }
`;

const submenu = styled.div`
  display: none;
  background-color: ${COLOR.grayScale_50};
  padding: 8px 12px;

  &.active {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  > ul {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
`;

const submenuTitle = styled.div`
  ${setFontStyle("Body3")};
  color: ${TEXT_COLOR.black_2};
`;

export default {
  mobileHeader,
  authAndMenuContainer,
  mobileDrawerContainer,
  mobileAuthAndMenuContainer,
  mobileMenu,
  submenu,
  submenuTitle,
};
