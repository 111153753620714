import SvgIconWrapper from "./SvgIconWrapper";

export default function UserFilledIcon(props: React.SVGAttributes<SVGElement>) {
  return (
    <SvgIconWrapper {...props}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_155_1178)">
          <path
            d="M20 3.33333C10.8 3.33333 3.33334 10.8 3.33334 20C3.33334 29.2 10.8 36.6667 20 36.6667C29.2 36.6667 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33333 20 3.33333ZM20 8.33333C22.7667 8.33333 25 10.5667 25 13.3333C25 16.1 22.7667 18.3333 20 18.3333C17.2333 18.3333 15 16.1 15 13.3333C15 10.5667 17.2333 8.33333 20 8.33333ZM20 32C15.8333 32 12.15 29.8667 10 26.6333C10.05 23.3167 16.6667 21.5 20 21.5C23.3167 21.5 29.95 23.3167 30 26.6333C27.85 29.8667 24.1667 32 20 32Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_155_1178">
            <rect width="40" height="40" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIconWrapper>
  );
}
