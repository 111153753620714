import styled from "styled-components";

import { COLOR, TEXT_COLOR } from "@sellernote/_sds-v2/src/styles/colors";
import { setFontStyle } from "@sellernote/_sds-v2/src/styles/typography";

const container = styled.div``;

const guideContainer = styled.div`
  padding-top: 15px;

  .guide {
    display: block;
    ${setFontStyle("Body4")};
    color: ${TEXT_COLOR.black_2};
  }

  .warning {
    color: ${COLOR.error_400};
  }
`;

export default {
  container,
  guideContainer,
};
