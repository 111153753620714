import React from "react";

import { ButtonIconInfo } from "@sellernote/_shared/src/headlessComponents/button/useButton";
import { useBodyScrollLock } from "@sellernote/_shared/src/hooks/common/useBodyScrollLock";
import ChevronLeftIcon from "@sellernote/_sds-v2/src/components/svgIcons/ChevronLeftIcon";
import XMarkDefaultIcon from "@sellernote/_sds-v2/src/components/svgIcons/XMarkDefaultIcon";

import { COLOR } from "../../../styles/colors";
import {
  BorderTypeConfiguration,
  InteractionTheme,
} from "../../../styles/interactionStyle";

import Button from "../../button/Button";
import Styled from "./index.styles";

interface BottomSheetProps {
  title?: React.ReactNode;
  /**
   * 바텀시트에서 뒤로가기 기능이 필요한 경우 함수 전달
   */
  handlePrev?: () => void;
  desc?: React.ReactNode;
  mainButtonInfo: ButtonInfo;
  /**
   * 보조 버튼(왼쪽에 위치)
   */
  subButtonInfo?: ButtonInfo;
  children: React.ReactNode;
  opensBottomSheet: boolean;
  setOpensBottomSheet: (val: boolean) => void;
  onClose?: () => void;
  height?: string;
  /**
   * 기존 바텀시트 스타일인 .contents-area 영역의 margin-top 여백을 유지하는지 여부 ( 기본값 true )
   */
  isContentMargin?: boolean;
  className?: string;
}

interface ButtonInfo {
  label: React.ReactNode;
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  disabled?: boolean;
  theme?: InteractionTheme;
  borderType?: BorderTypeConfiguration;
  iconInfo?: ButtonIconInfo;
  buttonType?: React.ButtonHTMLAttributes<HTMLButtonElement>["type"];
}

export type { BottomSheetProps, ButtonInfo };

/**
 * 사용시 유의사항: children의 역할
 * - BottomSheet 닫혔을떄: 오픈하는 트리거
 * - BottomSheet 열렸을때: BottomSheet의 컨텐츠
 */
export default function BottomSheet({
  title,
  handlePrev,
  desc,
  mainButtonInfo,
  subButtonInfo,
  children,
  opensBottomSheet,
  setOpensBottomSheet,
  onClose,
  height,
  isContentMargin = true,
  className,
}: BottomSheetProps) {
  const { lockBodyScroll, unlockBodyScroll } = useBodyScrollLock({
    enablesLocking: opensBottomSheet,
  });

  const hasPrev = typeof handlePrev === "function";

  const hasTitleContainer = Boolean(title) || Boolean(desc) || Boolean(hasPrev);

  const handleClose = () => {
    setOpensBottomSheet(false);

    if (onClose) return onClose();
  };

  // children 컴포넌트 내부의 상태 유지를 위해서, opensBottomSheet 상태가 children 을 제외하고 제어하도록 함.
  return (
    <Styled.container
      className={`${className ? className : ""} bottom-sheet`}
      onClick={(e) => e.stopPropagation()}
    >
      <Styled.bottomSheetContainer
        opensBottomSheet={opensBottomSheet}
        height={height}
        className="bottom-sheet-container"
      >
        {opensBottomSheet && <Styled.top />}

        {opensBottomSheet && hasTitleContainer && (
          <>
            <Styled.titleContainer hasDesc={!!desc}>
              {hasPrev && (
                <ChevronLeftIcon
                  width={24}
                  height={24}
                  onClick={handlePrev}
                  color={COLOR.grayScale_800}
                  className="custom-chevron-left-icon"
                />
              )}

              <Styled.titleAndDesc>
                {title && <span className="title">{title}</span>}

                {desc && <span className="desc">{desc}</span>}
              </Styled.titleAndDesc>

              {onClose && (
                <XMarkDefaultIcon
                  width={24}
                  height={24}
                  onClick={handleClose}
                  color={COLOR.grayScale_800}
                  className="x-mark-icon"
                />
              )}
            </Styled.titleContainer>
          </>
        )}

        <Styled.contentsArea
          className="contents-area"
          openBottomSheet={opensBottomSheet}
          hasHeaderContents={!!(title || desc)}
          isContentMargin={isContentMargin}
          onClick={() => setOpensBottomSheet(true)}
        >
          {children}
        </Styled.contentsArea>

        {opensBottomSheet && (
          <>
            <Styled.buttonContainer className="button-container">
              {subButtonInfo && (
                <Button
                  className="sub-button"
                  theme={subButtonInfo.theme ?? "tertiary"}
                  iconInfo={subButtonInfo.iconInfo}
                  size="normal"
                  borderType={subButtonInfo.borderType ?? "outlined"}
                  label={subButtonInfo.label}
                  handleClick={(e) => subButtonInfo.onClick(e)}
                  width="100%"
                  disabled={subButtonInfo.disabled}
                  buttonType={subButtonInfo.buttonType}
                />
              )}

              <Button
                theme={mainButtonInfo.theme ?? "primary"}
                iconInfo={mainButtonInfo.iconInfo}
                size="normal"
                borderType={mainButtonInfo.borderType ?? "filled"}
                label={mainButtonInfo.label}
                handleClick={(e) => mainButtonInfo.onClick(e)}
                width="100%"
                disabled={mainButtonInfo.disabled}
                buttonType={mainButtonInfo.buttonType}
              />
            </Styled.buttonContainer>
          </>
        )}
      </Styled.bottomSheetContainer>

      {opensBottomSheet && (
        <Styled.dimmedLayer onClick={handleClose} className="dimmed-layer" />
      )}
    </Styled.container>
  );
}
