import React from "react";
import Link from "next/link";

import ExpandAccordionClosedIcon from "@sellernote/_sds-v2/src/components/svgIcons/ExpandAccordionClosedIcon";
import ExpandAccordionOpenIcon from "@sellernote/_sds-v2/src/components/svgIcons/ExpandAccordionOpenIcon";
import { COLOR } from "@sellernote/_sds-v2/src/styles/colors";

import Styled from "./index.styles";

export default function MenuItem({
  label,
  path,
  selected,
  onSelect,
  handleDrawerClose,
  children,
}: {
  label: string;
  path?: string;
  selected?: boolean;
  onSelect?: (label: string) => void;
  handleDrawerClose?: () => void;
  children?: React.ReactNode;
}) {
  const handleMenuClick = () => {
    // select 해서 submenu를 보여줘야 하는 메뉴
    if (onSelect) {
      if (selected) {
        onSelect("");
      } else {
        onSelect(label);
      }
    }

    // path로 이동해야 하는 메뉴 (이동하면서 drawer를 닫아야 함)
    if (path && handleDrawerClose) {
      handleDrawerClose();
    }
  };

  return (
    <Styled.mobileMenu onClick={handleMenuClick}>
      {path ? (
        <div className={`link-label label`}>
          <Link href={path}>
            <a>{label}</a>
          </Link>
        </div>
      ) : (
        <div className={`${selected ? "active" : ""} label`}>
          {label}

          {selected ? (
            <ExpandAccordionOpenIcon
              width={16}
              height={16}
              color={COLOR.grayScale_800}
              className="icon"
            />
          ) : (
            <ExpandAccordionClosedIcon
              width={16}
              height={16}
              color={COLOR.grayScale_800}
              className="icon"
            />
          )}
        </div>
      )}

      {children && (
        <Styled.submenu className={`${selected ? "active" : ""}`}>
          {children}
        </Styled.submenu>
      )}
    </Styled.mobileMenu>
  );
}
