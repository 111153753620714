import { atom } from "jotai";

/**
 * Chat 버튼의 bottom 위치
 *
 * 유의사항: `특정 페이지에서만 위치를 변경하고 싶을때`는 직접 state를 변경하지말고 `useCustomizeFloatingChatButtonBottomPosition` 훅을 통해 사용할 것을 권장.
 * - 해당 페이지에서 나왔을때 default값으로 되돌려야 하므로, 관련 오작동을 방지하기 위함
 */
const FLOATING_CHAT_BUTTON_BOTTOM_POSITION = atom<number>(0);

const CHAT_BUTTON_UNREAD_COUNT = atom<number | undefined>(undefined);

const OPENS_POP_UP_FOR_SHIPDA = atom<boolean>(true);

export default {
  FLOATING_CHAT_BUTTON_BOTTOM_POSITION,
  CHAT_BUTTON_UNREAD_COUNT,
  OPENS_POP_UP_FOR_SHIPDA,
};
