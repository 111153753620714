import { useCheckIsMobile } from "@sellernote/_shared/src/utils/common/hook";

import { ManagerType, ShipdaService } from "..";
import ManagerSectionForDesktop from "./ManagerSectionForDesktop";
import ManagerSectionForMobile from "./ManagerSectionForMobile";

export default function ManagerSection({
  serviceType,
  currentManagerTypeForMobile,
}: {
  serviceType: ShipdaService;
  currentManagerTypeForMobile: ManagerType;
}) {
  const { isMobile } = useCheckIsMobile();

  return isMobile ? (
    <ManagerSectionForMobile
      serviceType={serviceType}
      currentManagerTypeForMobile={currentManagerTypeForMobile}
    />
  ) : (
    <ManagerSectionForDesktop serviceType={serviceType} />
  );
}
