import React, { useCallback, useRef } from "react";
import { useQueryClient } from "react-query";
import { useTranslation } from "next-i18next";
import { useRecoilState } from "recoil";

import TEAM_QUERY, {
  TEAM_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/TEAM_QUERY";
import { useCheckIsMobile } from "@sellernote/_shared/src/utils/common/hook";
import BottomSheet from "@sellernote/_sds-v2/src/components/bottomSheet/BottomSheet";
import Modal from "@sellernote/_sds-v2/src/components/Modal";
import { TEAM_ATOMS } from "@sellernote/shipda-kr/src/states/forwarding/team";

import Contents from "./Contents";

/** 로그인 했을 때에 여러 팀에서 초대된 내역을 순차적으로 띄우는 모달.
/* 새로고침 시에는 띄우지 않기 위해서 로그인 성공 시, 초대한 팀 리스트를 호출하고 Response 를 state에 재할당한 리스트로 관리하고,
/* 사용자가 확인한 모달정보는 제거하여 다시 띄우지 않도록 함. (재 접속시에는 띄움)
*/
export default function InvitationModal() {
  const { t } = useTranslation(["team"]);

  const { isMobile } = useCheckIsMobile();

  const [invitedTeamList, setInvitedTeamList] = useRecoilState(
    TEAM_ATOMS.INVITED_TEAM_LIST
  );

  const currentModalInfo = invitedTeamList[0];

  // 확인한 모달 정보는 지우는 형식으로 구현되어있기 때문에, 최초 초대된 팀의 개수를 Ref에 저장.
  const totalInvitedTeamCount = useRef(invitedTeamList.length);

  const currentInvitedTeamOrder = useRef(1);

  const queryClient = useQueryClient();

  const {
    mutate: acceptInvitedTeam,
    ResponseHandler: ResponseHandlerOfAcceptInvitedTeam,
  } = TEAM_QUERY.useAcceptInvitedTeam({
    onSuccess: () => {
      handleModalClose();

      queryClient.invalidateQueries(TEAM_QUERY_KEY_GEN.getAccountInfo());
    },
  });

  const handleInvitedTeamAccept = useCallback(
    (isAccept: boolean) => {
      acceptInvitedTeam({
        pathParams: { teamId: currentModalInfo.teamId, isAccept },
      });
    },
    [acceptInvitedTeam, currentModalInfo.teamId]
  );

  const handleModalClose = () => {
    setInvitedTeamList((prev) => prev.slice(1));

    currentInvitedTeamOrder.current += 1;
  };

  return (
    <div>
      {isMobile ? (
        <BottomSheet
          opensBottomSheet={true}
          setOpensBottomSheet={(val) => {
            handleModalClose();
          }}
          mainButtonInfo={{
            label: t("팀_초대_수락_버튼"),
            onClick: () => {
              handleInvitedTeamAccept(true);
            },
          }}
          subButtonInfo={{
            label: t("팀_초대_거절_버튼"),
            onClick: () => {
              handleInvitedTeamAccept(false);
            },
            theme: "critical",
          }}
        >
          <Contents
            currentModalInfo={currentModalInfo}
            totalInvitedTeamCount={totalInvitedTeamCount}
            currentInvitedTeamOrder={currentInvitedTeamOrder}
            handleModalClose={handleModalClose}
          />
        </BottomSheet>
      ) : (
        <Modal
          uiType="formInput"
          width="960px"
          body={
            <Contents
              currentModalInfo={currentModalInfo}
              totalInvitedTeamCount={totalInvitedTeamCount}
              currentInvitedTeamOrder={currentInvitedTeamOrder}
              handleModalClose={handleModalClose}
            />
          }
          active={true}
          actionPositive={{
            label: t("팀_초대_수락_버튼"),
            handleClick: () => {
              handleInvitedTeamAccept(true);
            },
          }}
          actionNegative={{
            label: t("팀_초대_거절_버튼"),
            handleClick: () => {
              handleInvitedTeamAccept(false);
            },
            isCritical: true,
          }}
          actionOption={{
            label: t("팀_초대_나중에하기_버튼"),
            handleClick: () => {
              handleModalClose();
            },
          }}
        />
      )}

      {ResponseHandlerOfAcceptInvitedTeam}
    </div>
  );
}
