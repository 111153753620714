import { useRouter } from "next/router";

import { YEAR_AS_MILLISECONDS } from "@sellernote/_shared/src/utils/common/date";
import { useCheckIsMobile } from "@sellernote/_shared/src/utils/common/hook";

export default function TempLanguageButton() {
  const { isMobile } = useCheckIsMobile();

  const router = useRouter();

  const { pathname, query, asPath } = router;

  return (
    <div
      style={{ position: "absolute", left: isMobile ? 80 : 600, zIndex: 10 }}
    >
      <button
        onClick={() => {
          console.log("한국어");

          router.push({ pathname, query }, asPath, { locale: "ko" });

          document.cookie = `NEXT_LOCALE=ko; path=/; max-age=${YEAR_AS_MILLISECONDS};`;
        }}
      >
        '한국어'
      </button>
      <button
        onClick={() => {
          console.log("English");

          router.push({ pathname, query }, asPath, { locale: "en" });

          document.cookie = `NEXT_LOCALE=en; path=/; max-age=${YEAR_AS_MILLISECONDS};`;
        }}
      >
        'English'
      </button>
    </div>
  );
}
