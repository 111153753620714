/**
 * Zendesk
 * - Web Widget을 통해 Chat을 구현함.
 * - API 문서: https://developer.zendesk.com/api-reference/widget-messaging/web/core/
 * - 주의: 'classic SDK'라고 이전 SDK가 있는데, 우리는 새 SDK를 사용중이이므로 'classic SDK'관련 문서를 봐서는 안 됨.
 */

function openZendeskChatModal() {
  if (typeof window === "undefined") return;

  // zendesk에서 타입 제공 하지 않아 any로 처리
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const zendesk = (window as any).zE;
  if (!zendesk) return;

  zendesk("messenger", "open");
}

/**
 * Zendesk 버튼 사용을 위한 초기화 단계에서 실행이 필요한 로직을 관리
 * 주의: 프로젝트 라이프사이클에서 한번만 실행되어야하므로 반드시 'initChatButtonUI' 함수를 통해서만 실행할것
 */
function initZendeskButtonUI(setUnreadCount: (unreadCount: number) => void) {
  if (typeof window === "undefined") return;

  // zendesk에서 타입 제공 하지 않아 any로 처리
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const zendesk = (window as any).zE;
  if (!zendesk) return;

  zendesk("messenger:on", "unreadMessages", (unreadCount: number) => {
    setUnreadCount(unreadCount);
  });
}

export { openZendeskChatModal, initZendeskButtonUI };
