import { atom } from "jotai";

/**
 * GTM으로 로그인 이벤트를 보내야 하는지 체크하는 Flag
 * 아래 요구사항을 충족하기 위해 전역 state로 관리함
 * - 로그인 submit할때만 로그인 이벤트를 보내기
 * - dataLayer에 유저 정보를 추가한 후 로그인 이벤트를 보내기
 */
const NEED_TO_SEND_GTM_LOGIN_EVENT = atom<boolean>(false);

export default {
  NEED_TO_SEND_GTM_LOGIN_EVENT,
};
