import React, { useMemo } from "react";

import Icon from "@sellernote/_shared/src/componentsToMoveToV1/Icon";
import { IconType } from "@sellernote/_shared/src/componentsToMoveToV1/Icon/DATA";
import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";

import Styled from "./index.styles";

// TODO: circle은 아직은 사용하는데가 없어 미구현. 쓸때 구현하기로 함
export type InputCheckBoxShape = "rect" | "circle";

export default function InputCheckBox({
  label,
  shape,
  handleCheck,
  desc,
  checked,
  disabled,
  size,
  className,
}: {
  label: React.ReactNode;
  shape: InputCheckBoxShape;
  handleCheck: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  desc?: React.ReactNode;
  checked?: boolean;
  disabled?: boolean;
  size?: number;
  className?: string;
}) {
  const color = useMemo(getColor, [checked, disabled]);

  const icon = useMemo(getIcon, [checked, disabled, shape, color, size]);

  function getIcon() {
    let iconType: IconType = shape === "rect" ? "checkStrokeBox" : "radio";

    if (checked) {
      iconType = shape === "rect" ? "checkFilledBoxChecked" : "radioSelected";
    }

    if (disabled) {
      if (!checked) {
        iconType =
          shape === "rect"
            ? "checkStrokeBoxDisabled"
            : "checkStrokeCircleDisabled";
      }
    }

    return <Icon type={iconType} size={size || 1} color={color} />;
  }

  function getColor() {
    let color = COLOR.grayScale_400;

    if (checked) {
      color = COLOR.primaryBlue;
    }

    if (disabled) {
      if (checked) {
        color = COLOR.primaryBlue_40;
      } else {
        color = COLOR.grayScale_300;
      }
    }

    return color;
  }

  return (
    <Styled.container
      className={`${className ? className : ""} input-check-box`}
      onClick={(e) => handleCheck(e)}
      shape={shape}
      checked={checked}
      disabled={disabled}
      color={color}
      hasDesc={Boolean(desc)}
    >
      <div className="label-container">
        {icon}

        <div className="label">{label}</div>
      </div>

      {desc && <div className="desc">{desc}</div>}
    </Styled.container>
  );
}
