import styled, { css } from "styled-components";

import { mobile } from "@sellernote/_shared/src/styles/functions";

const logoutContainer = styled.div`
  ${mobile(css`
    margin-top: 80px;
  `)}
`;

export default {
  logoutContainer,
};
