import SvgIconWrapper from "./SvgIconWrapper";

export default function UserPlusIcon(props: React.SVGAttributes<SVGElement>) {
  return (
    <SvgIconWrapper {...props}>
      <svg
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_2497_14813)">
          <path
            d="M9.3 4.8001C9.3 6.5676 7.8675 8.0001 6.1 8.0001C4.3325 8.0001 2.9 6.5676 2.9 4.8001C2.9 3.0326 4.3325 1.6001 6.1 1.6001C7.8675 1.6001 9.3 3.0326 9.3 4.8001ZM0.5 13.6576C0.5 11.1951 2.495 9.2001 4.9575 9.2001H7.2425C9.705 9.2001 11.7 11.1951 11.7 13.6576C11.7 14.0676 11.3675 14.4001 10.9575 14.4001H1.2425C0.8325 14.4001 0.5 14.0676 0.5 13.6576ZM13.1 9.4001V7.8001H11.5C11.1675 7.8001 10.9 7.5326 10.9 7.2001C10.9 6.8676 11.1675 6.6001 11.5 6.6001H13.1V5.0001C13.1 4.6676 13.3675 4.4001 13.7 4.4001C14.0325 4.4001 14.3 4.6676 14.3 5.0001V6.6001H15.9C16.2325 6.6001 16.5 6.8676 16.5 7.2001C16.5 7.5326 16.2325 7.8001 15.9 7.8001H14.3V9.4001C14.3 9.7326 14.0325 10.0001 13.7 10.0001C13.3675 10.0001 13.1 9.7326 13.1 9.4001Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_2497_14813">
            <rect
              width="16"
              height="16"
              fill="white"
              transform="translate(0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIconWrapper>
  );
}
