import SvgIconWrapper from "./SvgIconWrapper";

export default function SubmenuPlayerIcon(
  props: React.SVGAttributes<SVGElement>
) {
  return (
    <SvgIconWrapper {...props}>
      <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_2353_3806)">
          <path
            d="M1.89835 21.8697V18.6271H0.711914V21.8697C0.711914 22.9817 1.61681 23.9068 2.72886 23.9068H3.9153C2.80325 23.9068 1.89835 22.9817 1.89835 21.8697Z"
            fill="#D4E1F4"
          />
          <path
            d="M3.9153 4.0932H2.72886C1.61681 4.0932 0.711914 5.01825 0.711914 6.13031V17.9152H1.89835V6.13031C1.89835 5.01825 2.80325 4.0932 3.9153 4.0932Z"
            fill="#D4E1F4"
          />
          <path
            d="M28 6.11016C28 4.60301 26.7783 3.38135 25.2712 3.38135H2.72881C1.22166 3.38135 0 4.60301 0 6.11016V21.8305C0 23.3377 1.22166 24.5593 2.72881 24.5593H25.2712C26.7783 24.5593 28 23.3377 28 21.8305V6.11016ZM25.2712 23.9068H2.72881C1.61676 23.9068 0.711864 22.9817 0.711864 21.8697V18.6271H27.2881V21.8697C27.2881 22.9817 26.3832 23.9068 25.2712 23.9068ZM27.2881 17.9152H0.711864V6.13032C0.711864 5.01827 1.61676 4.09321 2.72881 4.09321H25.2712C26.3832 4.09321 27.2881 5.01827 27.2881 6.13032V17.9152Z"
            fill="#475FB0"
          />
          <path
            d="M23.631 20.9407H9.07627V20.0355C9.07256 19.9414 9.03178 19.8525 8.96249 19.7887C8.89344 19.7248 8.80167 19.6909 8.70736 19.6949H6.18038C6.08653 19.6912 5.995 19.725 5.92617 19.789C5.85712 19.8529 5.8168 19.9417 5.81356 20.0355V20.9407H3.22239C3.02565 20.9407 2.86646 21.1001 2.86646 21.2966C2.86646 21.4931 3.02565 21.6525 3.22239 21.6525H5.81356V22.464C5.81726 22.6663 5.97808 22.8308 6.18038 22.839H8.70759C8.91035 22.8311 9.07186 22.6668 9.07627 22.464V21.6525H23.631C23.8278 21.6525 23.987 21.4931 23.987 21.2966C23.987 21.1001 23.8278 20.9407 23.631 20.9407ZM8.3644 22.1271H6.52542V20.4068H8.3644V22.1271Z"
            fill="#475FB0"
          />
          <path
            d="M11.2223 15.3801C11.2763 15.4098 11.337 15.4253 11.3984 15.4251C11.4652 15.4251 11.5305 15.4068 11.5871 15.3718L18.3537 11.1852C18.4587 11.1205 18.5224 11.0061 18.5224 10.8828C18.5226 10.7597 18.4589 10.6453 18.3544 10.5802L11.5885 6.39355C11.4768 6.32565 11.337 6.32241 11.2223 6.38521C11.1081 6.44754 11.0363 6.56618 11.0339 6.69618V15.0692C11.0363 15.1992 11.1081 15.318 11.2223 15.3801ZM11.7458 7.33505L17.4792 10.8826L11.7458 14.4305V7.33505Z"
            fill="#54C3D6"
          />
        </g>
        <defs>
          <clipPath id="clip0_2353_3806">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIconWrapper>
  );
}
