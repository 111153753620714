import React from "react";

import Styled from "./index.styles";

export default function SnsList() {
  return (
    <Styled.snsList>
      <li>
        <a
          target="_blank"
          href="https://instagram.com/globalshipda/"
          rel="noopener noreferrer"
        >
          Instagram
        </a>
      </li>

      <li>
        <a
          target="_blank"
          href="https://www.youtube.com/channel/UCtP8od_ijpAr6n1siRbXseQ"
          rel="noopener noreferrer"
        >
          Youtube
        </a>
      </li>

      <li>
        <a
          target="_blank"
          href="https://www.ship-da.com/blog?utm_source=inblog&utm_medium=referral&utm_campaign=homepage&utm_id=inblog_main&utm_content=inblog_main"
          rel="noopener noreferrer"
        >
          Blog
        </a>
      </li>
    </Styled.snsList>
  );
}
