import { ChangeEvent, KeyboardEvent, useRef, useState } from "react";

import { InputTextFieldProps } from "@sellernote/_shared/src/headlessComponents/form/useInputTextField";
import useClickOutsideOfElement from "@sellernote/_shared/src/hooks/common/useClickOutsideOfElement";
import newId from "@sellernote/_shared/src/utils/common/newId";
import {
  getTrimmedValue,
  removeLeadingBlank,
} from "@sellernote/_shared/src/utils/common/string";
import XMarkCircleIcon from "@sellernote/_sds-v2/src/components/svgIcons/XMarkCircleIcon";

import { COLOR } from "../../../styles/colors";

import Styled from "./index.styles";

type InputTextWithChipsProps = Pick<
  InputTextFieldProps,
  "inputMode" | "labelInfo" | "width" | "placeholder" | "disabled" | "className"
> & {
  chipDataList: Chip[];
  setChipDataList: (chipDataList: Chip[]) => void;
  validate: (value: string) => boolean;
};

export interface Chip {
  id: number;
  value: string;
  isError: boolean;
}

export default function InputTextWithChips({
  inputMode,
  labelInfo,
  chipDataList,
  setChipDataList,
  validate,
  width,
  placeholder,
  disabled,
  className,
}: InputTextWithChipsProps) {
  // label id를 unique하게 사용하기 위함
  const [inputId] = useState(() => newId("input-text-with-chips-id-"));

  const inputRef = useRef<HTMLInputElement>(null);

  const chipId = useRef<number>(1);

  const [inputValue, setInputValue] = useState("");

  const generateChip = () => {
    if (!inputValue) return;

    setChipDataList([
      ...chipDataList,
      {
        id: chipId.current,
        value: inputValue,
        isError: validate(inputValue),
      },
    ]);

    setInputValue("");

    chipId.current += 1;

    inputRef.current?.focus();
  };

  const { targetElementRef } = useClickOutsideOfElement({
    onClickOutside: generateChip,
  });

  const generateChipFromCopyAndPaste = (inputValue: string) => {
    const inputValueList = inputValue
      .split(",")
      .map((value) => value.split(" "))
      .flat()
      .filter((value) => value);

    const newChipList = inputValueList.map((value, i) => ({
      id: chipId.current + i,
      value: removeLeadingBlank(value),
      isError: validate(removeLeadingBlank(value)),
    }));

    setChipDataList([...chipDataList, ...newChipList]);

    chipId.current += newChipList.length;

    setInputValue("");
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    // 콤마(,) 와 띄어쓰기를 입력하거나 붙여넣기를 했을 경우 칩 생성
    if (e.target.value.includes(",") || e.target.value.includes(" ")) {
      return generateChipFromCopyAndPaste(e.target.value);
    }

    setInputValue(removeLeadingBlank(e.target.value));
  };

  const removeLastChip = () => {
    if (!chipDataList.length) return;

    if (inputValue.length) return; // input 에 입력 중인 경우 삭제하지 않음

    const lastChip = chipDataList[chipDataList.length - 1];

    setChipDataList(chipDataList.filter((item) => item.id !== lastChip.id));

    inputRef.current?.focus();
  };

  const removeLastChipFromBackspaceKey = (keyCode: string) => {
    if (keyCode !== "Backspace") return;

    removeLastChip();
  };

  const generateChipFromEnterKey = (keyValue: string) => {
    if (keyValue !== "Enter") return;

    generateChip();
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    generateChipFromEnterKey(e.key);

    removeLastChipFromBackspaceKey(e.code);
  };

  const handleRemove = (id: number) => {
    setChipDataList(chipDataList.filter((item) => item.id !== id));

    inputRef.current?.focus();
  };

  return (
    <Styled.container
      className={`${className ? className : ""} input-text-field`}
      width={width}
      disabled={disabled}
      ref={targetElementRef}
    >
      <label
        htmlFor={inputId}
        className={`${(labelInfo.isLabelHidden && "visually-hidden") ?? ""}`}
      >
        {labelInfo.label}
      </label>

      <Styled.inputContainer>
        {chipDataList.map(({ id, value, isError }) => (
          <Styled.chip key={`chip-item-${id}`} isError={isError}>
            {value}

            <XMarkCircleIcon
              color={isError ? COLOR.error_300 : COLOR.grayScale_400}
              width={16}
              height={16}
              onClick={() => handleRemove(id)}
            />
          </Styled.chip>
        ))}

        <input
          type="text"
          ref={inputRef}
          id={inputId}
          value={inputValue || ""}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          inputMode={inputMode}
          disabled={disabled}
          placeholder={
            chipDataList.length ? "" : placeholder || "입력해주세요."
          }
          onBlur={() => {
            setInputValue && setInputValue(getTrimmedValue(inputValue));
          }}
        />
      </Styled.inputContainer>
    </Styled.container>
  );
}
