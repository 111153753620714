/* eslint-disable unused-imports/no-unused-imports */
import path from "path";

import React, { useState } from "react";
import Link from "next/link";

import { useCheckIsMobile } from "@sellernote/_shared/src/utils/common/hook";
import ExpandAccordionClosedIcon from "@sellernote/_sds-v2/src/components/svgIcons/ExpandAccordionClosedIcon";
import ExpandAccordionOpenIcon from "@sellernote/_sds-v2/src/components/svgIcons/ExpandAccordionOpenIcon";
import { COLOR } from "@sellernote/_sds-v2/src/styles/colors";

import { Menu } from "../useMenuList";
import MobileMenuItem from "./MobileMenuItem";
import Styled from "./index.styles";

export default function MainMenu({ menuList }: { menuList: Menu[] }) {
  const { isMobile } = useCheckIsMobile();

  const [activeMenuIndex, setActiveMenuIndex] = useState<number | null>(null);

  return (
    <nav>
      {isMobile ? (
        <Styled.mobileMainMenuContainer>
          {menuList.map(({ label, submenuList, path }, i) => (
            <li className="menu-item" key={i}>
              {path ? (
                <h3 className="title">
                  <Link href={path}>
                    <a target="_blank">{label}</a>
                  </Link>
                </h3>
              ) : (
                <h3
                  className="title"
                  onClick={() => {
                    if (activeMenuIndex !== i) return setActiveMenuIndex(i);
                    return setActiveMenuIndex(null);
                  }}
                >
                  {label}

                  {activeMenuIndex === i ? (
                    <ExpandAccordionOpenIcon
                      width={16}
                      height={16}
                      color={COLOR.grayScale_200}
                      className="icon"
                    />
                  ) : (
                    <ExpandAccordionClosedIcon
                      width={16}
                      height={16}
                      color={COLOR.grayScale_200}
                      className="icon"
                    />
                  )}
                </h3>
              )}

              {submenuList && (
                <Styled.mobileSubmenuList>
                  {submenuList.map((submenu, si) => (
                    <MobileMenuItem
                      key={si}
                      {...{ submenu, si }}
                      isSelectedSubmenu={activeMenuIndex === i}
                    />
                  ))}
                </Styled.mobileSubmenuList>
              )}
            </li>
          ))}
        </Styled.mobileMainMenuContainer>
      ) : (
        <Styled.mainMenuContainer>
          {menuList.map(({ label, submenuList, path, width }, i) => (
            <Styled.mainMenuItem key={i} width={width}>
              {path ? (
                <h3 className="title">
                  <Link href={path}>
                    <a target="_blank">{label}</a>
                  </Link>
                </h3>
              ) : (
                <h3 className="title">{label}</h3>
              )}

              {submenuList && (
                <Styled.submenuList>
                  {submenuList.map((submenu, si) => (
                    <li className="sub-menu-item" key={si}>
                      <Link href={submenu.path}>
                        <a
                          target={
                            submenu.path.includes("http") ? "_blank" : "_top"
                          }
                        >
                          {submenu.labelForFooter}
                        </a>
                      </Link>
                    </li>
                  ))}
                </Styled.submenuList>
              )}
            </Styled.mainMenuItem>
          ))}
        </Styled.mainMenuContainer>
      )}
    </nav>
  );
}
