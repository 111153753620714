import styled, { css } from "styled-components";

import { showVerticalScrollBarAlways } from "@sellernote/_shared/src/styles/functions";
import { COLOR, TEXT_COLOR } from "@sellernote/_sds-v2/src/styles/colors";
import { setFontStyle } from "@sellernote/_sds-v2/src/styles/typography";

import { setDimmedLayer } from "../../../styles/elevation";

const container = styled.div``;

const bottomSheetContainer = styled.div<{
  opensBottomSheet: boolean;
  height?: string;
}>`
  ${({ opensBottomSheet, height }) => {
    if (!opensBottomSheet) return css``;

    return css`
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      width: 100%;
      padding: 0;
      z-index: 102;

      ${height === "100vh"
        ? css`
            height: 100vh;
            height: -webkit-fill-available; // ios safari 에서 100vh 로 설정한 경우, url 바때문에 화면이 잘리는 현상을 방지.
            height: fill-available;
          `
        : height
        ? css`
            height: ${height};
          `
        : css`
            height: calc(100% - 45px);
          `};
    `;
  }}
`;

const top = styled.div`
  height: 16px;
  border-radius: 16px 16px 0px 0px;
  background-color: ${COLOR.wh};
`;

const titleContainer = styled.div<{ hasDesc: boolean }>`
  display: flex;
  align-items: ${({ hasDesc }) => (hasDesc ? "flex-start" : "center")};
  justify-content: space-between;
  gap: 8px;
  padding: ${({ hasDesc }) => (hasDesc ? "4px 16px 8px" : "12px 16px")};
  background-color: ${COLOR.wh};

  > .custom-chevron-left-icon,
  > .x-mark-icon {
    cursor: pointer;
  }
`;

const titleAndDesc = styled.div`
  flex: 1;

  > .title,
  > .desc {
    display: block;
    color: ${TEXT_COLOR.black_1};
  }

  > .title {
    ${setFontStyle("Head6")};
  }

  > .desc {
    margin-top: 4px;
    ${setFontStyle("Body4")};
  }
`;

const contentsArea = styled.div<{
  openBottomSheet: boolean;
  hasHeaderContents: boolean;
  /**
   * 기존 바텀시트 스타일인 .contents-area 영역의 margin-top 여백을 유지하는지 여부 ( 기본값 true )
   */
  isContentMargin: boolean;
}>`
  padding-top: ${({ isContentMargin, hasHeaderContents }) =>
    !isContentMargin ? 0 : hasHeaderContents ? "16px" : "32px"};

  overflow-y: auto;
  background-color: ${COLOR.wh};

  ${({ openBottomSheet }) => {
    if (!openBottomSheet)
      return css`
        height: auto;
        padding: 0;
      `;

    return css`
      height: 100%;
      padding: 0 16px 78px 16px;
    `;
  }}

  ${showVerticalScrollBarAlways()};
`;

const buttonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 16px 16px 8px 16px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.7) 0%,
    #ffffff 100%
  );
`;

const dimmedLayer = styled.div`
  ${setDimmedLayer()};
  z-index: 101;
`;

export default {
  container,
  top,
  bottomSheetContainer,
  titleContainer,
  titleAndDesc,
  contentsArea,
  buttonContainer,
  dimmedLayer,
};
