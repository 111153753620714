import { APP_REGION, IS_ON_BROWSER } from "../../constants";
import { initChannelIOButtonUI } from "./channelIO";

let isChatButtonUIInitialized = false;
/**
 * Chat 버튼 사용을 위한 초기화 단계에서 실행이 필요한 로직을 관리
 *
 * `isChatButtonUIInitialized` 변수를 통해 프로젝트 라이프사이클 전체에서 한 번만 실행되도록 처리
 * - 여러번 실행되면 이벤트핸들러들이 중복등록되는 문제가 생김
 */
export default function initChatButtonUI(
  setUnreadCount: (unreadCount: number) => void
) {
  if (isChatButtonUIInitialized || !IS_ON_BROWSER) return;

  if (!APP_REGION) return;

  initChannelIOButtonUI(setUnreadCount);
  isChatButtonUIInitialized = true;
}
