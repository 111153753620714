import { useCallback, useEffect, useMemo, useState } from "react";
import { useRouter } from "next/router";
import { useRecoilValue, useSetRecoilState } from "recoil";

import TEAM_QUERY from "@sellernote/_shared/src/queries/forwarding/TEAM_QUERY";
import { FORWARDING_AUTH_ATOMS } from "@sellernote/_shared/src/states/forwarding/auth";
import Modal from "@sellernote/_sds-v2/src/components/Modal";
import { TEAM_ATOMS } from "@sellernote/shipda-kr/src/states/forwarding/team";

/**
 * 초대 메일로 들어온 경우 초대된 팀 모달을 띄우는 hook
 * @param currentUserEmail 현재 로그인 된 사용자의 이메일
 */
export default function useAccessedByEmailInvitationModal({
  currentUserEmail,
}: {
  currentUserEmail?: string;
}) {
  const loggedIn = useRecoilValue(FORWARDING_AUTH_ATOMS.USER_LOGGED_IN);

  const previousPath =
    (typeof window !== "undefined" &&
      window.sessionStorage?.getItem("previousPath")) ||
    "/";

  // 초대된 팀 아이디와 접속한 메일의 이메일을 가져온다.
  const {
    query: { invitedTeamId, userEmail },
  } = useRouter();

  const setInvitationTeamList = useSetRecoilState(TEAM_ATOMS.INVITED_TEAM_LIST);

  const [opensInvitedUserMismatchModal, setOpensInvitedUserMismatchModal] =
    useState(false);

  const { refetch: refetchInvitationTeamList } =
    TEAM_QUERY.useGetInvitedTeamList({
      enabled: false,
      onSuccess: (invitedTeamList) => {
        if (!invitedTeamList.length) return;

        if (!invitedTeamId) return;

        // 로그인 되어있지 않았던 사용자가 초대메일로 들어온 경우 login 페이지를 거쳐 들어오게 되는데, 이 경우는 초대 리스트를 모두 보여준다.
        if (previousPath.includes("/login")) {
          setInvitationTeamList(invitedTeamList);
          return;
        }

        const invitedTeamIdNumber = Array.isArray(invitedTeamId)
          ? Number(invitedTeamId[0])
          : Number(invitedTeamId);

        const invitedTeamInfoByMail = invitedTeamList.find(
          (teamInfo) => teamInfo.teamId === invitedTeamIdNumber
        );

        if (!invitedTeamInfoByMail) return;

        setInvitationTeamList([invitedTeamInfoByMail]);
      },
    });

  const confirmToShowInvitedTeamModal = useCallback(() => {
    // 로그인 되어있는 경우 로그인 된 이메일과 초대메일로 들어온 이메일이 같은지 확인한다.
    if (currentUserEmail !== userEmail)
      return setOpensInvitedUserMismatchModal(true);

    // 같으면 초대된 팀 모달 띄우기
    refetchInvitationTeamList();
  }, [currentUserEmail, userEmail, refetchInvitationTeamList]);

  const InvitedUserMismatchModal = useMemo(() => {
    return (
      <Modal
        uiType="onlyDescription"
        active={opensInvitedUserMismatchModal}
        desc="접속하신 계정과 초대받은 계정이 다릅니다. 초대받은 계정으로 다시 로그인해주세요."
        actionPositive={{
          label: "확인",
          handleClick: () => {
            setOpensInvitedUserMismatchModal(false);
          },
        }}
      />
    );
  }, [opensInvitedUserMismatchModal]);

  useEffect(() => {
    if (!invitedTeamId) return;

    if (!loggedIn) return;

    confirmToShowInvitedTeamModal();
  }, [confirmToShowInvitedTeamModal, invitedTeamId, loggedIn]);

  return { InvitedUserMismatchModal };
}
