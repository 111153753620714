import { COLOR } from "@sellernote/_sds-v2/src/styles/colors";
import { TypeLevel } from "@sellernote/_sds-v2/src/styles/typography";

import Styled from "./index.styles";

const THEME_COLOR = [
  "#1976D2",
  "#283593",
  "#2E7D32",
  "#336669",
  "#44692B",
  "#44888D",
  "#54C3D6",
  "#58624E",
  "#5E35B1",
  "#7BB0ED",
  "#8D6E63",
  "#AD1457",
  "#D32F2F",
  "#D67BED",
  "#E595B4",
  "#ED9D7B",
];

export default function UserIcon({
  id,
  name,
  handleClick,
  size,
  fontStyle,
}: {
  id?: number;
  name?: string | null;
  handleClick?: () => void;
  size: number;
  fontStyle: TypeLevel;
}) {
  const firstTwoLettersOfName = name?.slice(0, 2) ?? "";

  // User 배경 컬러 지정. 재로그인 시에도 같은 컬러가 유지되면서 THEME_COLOR 의 총 16개 컬러가 다양하게 나오도록 id 로 연산.
  const setThemeColor = (id?: number) => {
    if (!id) return COLOR.primary_300;

    const idNumberTotal = String(id)
      .split("")
      .reduce((acc, cur) => acc + Number(cur), 0);

    const index =
      idNumberTotal > 16 ? Math.ceil(idNumberTotal % 16) : idNumberTotal - 1;

    return THEME_COLOR[index];
  };

  // 로딩 상태거나 정보가 없는 경우, 회색 아이콘 표시
  const isLoading = !id || !name;

  return (
    <Styled.userIconButton
      backgroundColor={isLoading ? COLOR.grayScale_700 : setThemeColor(id)}
      onClick={handleClick}
      size={size}
      disabled={isLoading}
      className="user-icon"
    >
      <Styled.userName size={size} fontStyle={fontStyle}>
        {firstTwoLettersOfName}
      </Styled.userName>
    </Styled.userIconButton>
  );
}
