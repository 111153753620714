import Link from "next/link";

import Badge from "@sellernote/_sds-v2/src/components/Badge";
import { COLOR, TEXT_COLOR } from "@sellernote/_sds-v2/src/styles/colors";

import Styled from "./index.styles";

function SubmenuItem({
  label,
  badges,
  path,
  desc,
  Icon,
}: {
  label: string;
  badges?: string[];
  path: string;
  desc: string;
  Icon: React.ReactNode;
}) {
  const isExternalLink = path.includes("http");

  return (
    <Styled.submenuItem>
      <Link href={path}>
        <a target={isExternalLink ? "_blank" : "_top"}>
          <Styled.iconBlock>{Icon}</Styled.iconBlock>

          <Styled.contentsBlock>
            <Styled.labelAndBadges>
              <span className="label">{label}</span>

              {badges && (
                <Styled.badges>
                  {badges.map((badge) => (
                    <Badge
                      key={badge}
                      borderType="filled"
                      colorInfo={{
                        background: COLOR.grayScale_600,
                        text: TEXT_COLOR.white_1,
                      }}
                      label={badge}
                      className="custom-badge"
                    />
                  ))}
                </Styled.badges>
              )}
            </Styled.labelAndBadges>

            <span className="desc">{desc}</span>
          </Styled.contentsBlock>
        </a>
      </Link>
    </Styled.submenuItem>
  );
}

export default SubmenuItem;
