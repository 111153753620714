import styled, { css } from "styled-components";

import { mobile } from "@sellernote/_shared/src/styles/functions";
import { COLOR, TEXT_COLOR } from "@sellernote/_sds-v2/src/styles/colors";
import { setElevationStyle } from "@sellernote/_sds-v2/src/styles/elevation";
import { setFontStyle } from "@sellernote/_sds-v2/src/styles/typography";

const desktopHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  min-width: 1280px;

  ${mobile(css`
    display: none;
  `)}

  > .build-info {
    color: gray;
    position: absolute;
    top: 0;
    right: 2px;
    font-size: 12px;
  }
`;

const leftContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 16px 0;
`;

const menuContainer = styled.ul`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 80px;
`;

const menu = styled.li`
  position: relative;
  margin-right: 24px;

  > .label {
    ${setFontStyle("SubHead2")}
    color: ${TEXT_COLOR.black_2};
    display: flex;
    align-items: center;
    word-break: keep-all;
    padding: 8px 4px;
    cursor: pointer;

    a,
    a:visited {
      color: ${TEXT_COLOR.black_2};
    }
  }
`;

const submenu = styled.div`
  display: none;
  position: absolute;
  top: 30px;
  left: calc(50% - 40px);
  z-index: 102;
  width: fit-content;

  &.active {
    display: block;
  }

  > .arrow-block {
    position: relative;
    top: 1px;
    left: 20px;
    z-index: 103;
  }
`;

const submenuPanel = styled.div<{ width?: string }>`
  position: relative;
  top: -41px;
  background-color: ${COLOR.wh};
  width: ${(props) => props.width || "100%"};
  display: flex;
  padding: 24px;
  gap: 24px;
  border-radius: 8px;
  ${setElevationStyle("level4")};

  > ul {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
`;

/** 메뉴 상단 삼각형 꼭지점(arrow-block) 형태 변경으로 title이 가려지는 이슈가 있어 z-index 추가 */
const submenuTitle = styled.div`
  position: relative;
  z-index: 104;
  ${setFontStyle("Body3")};
  color: ${TEXT_COLOR.black_2};
`;

const authContainer = styled.div`
  display: flex;
  align-items: center;

  > div:not(:last-child),
  > button:not(:last-child) {
    margin-right: 24px;
  }
`;

export default {
  desktopHeader,
  leftContainer,
  menuContainer,
  authContainer,
  menu,
  submenu,
  submenuPanel,
  submenuTitle,
};
