import SvgIconWrapper from "./SvgIconWrapper";

export default function UserSolidIcon(props: React.SVGAttributes<SVGElement>) {
  return (
    <SvgIconWrapper {...props}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_155_1184)">
          <path
            d="M20 3.33334C10.8 3.33334 3.33334 10.8 3.33334 20C3.33334 29.2 10.8 36.6667 20 36.6667C29.2 36.6667 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33334 20 3.33334ZM11.7833 30.4667C12.5 28.9667 16.8667 27.5 20 27.5C23.1333 27.5 27.5167 28.9667 28.2167 30.4667C25.95 32.2667 23.1 33.3333 20 33.3333C16.9 33.3333 14.05 32.2667 11.7833 30.4667ZM30.6 28.05C28.2167 25.15 22.4333 24.1667 20 24.1667C17.5667 24.1667 11.7833 25.15 9.4 28.05C7.7 25.8167 6.66667 23.0333 6.66667 20C6.66667 12.65 12.65 6.66668 20 6.66668C27.35 6.66668 33.3333 12.65 33.3333 20C33.3333 23.0333 32.3 25.8167 30.6 28.05ZM20 10C16.7667 10 14.1667 12.6 14.1667 15.8333C14.1667 19.0667 16.7667 21.6667 20 21.6667C23.2333 21.6667 25.8333 19.0667 25.8333 15.8333C25.8333 12.6 23.2333 10 20 10ZM20 18.3333C18.6167 18.3333 17.5 17.2167 17.5 15.8333C17.5 14.45 18.6167 13.3333 20 13.3333C21.3833 13.3333 22.5 14.45 22.5 15.8333C22.5 17.2167 21.3833 18.3333 20 18.3333Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_155_1184">
            <rect width="40" height="40" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIconWrapper>
  );
}
