import styled, { css } from "styled-components";

import { mobile } from "@sellernote/_shared/src/styles/functions";
import { COLOR, TEXT_COLOR } from "@sellernote/_sds-v2/src/styles/colors";
import { setFontStyle } from "@sellernote/_sds-v2/src/styles/typography";

const container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 76px 0 77px 0;

  ${mobile(css`
    padding: 24px 0 31px 0;
  `)};

  .invited-team-guide {
    ${setFontStyle("SubHead1")};
    color: ${TEXT_COLOR.black_2};

    ${mobile(css`
      ${setFontStyle("SubHead2")};
    `)};
  }

  .invited-team-info {
    margin-top: 16px;
    margin-bottom: 40px;
    ${setFontStyle("SubHead2")};
    color: ${TEXT_COLOR.black_2};

    ${mobile(css`
      margin-bottom: 24px;
      ${setFontStyle("SubHead3")};
    `)};
  }

  .order-and-total-count {
    margin-top: 40px;
    ${setFontStyle("SubHead1")};
    color: ${TEXT_COLOR.black_2};

    strong {
      color: ${COLOR.point_400};
    }

    ${mobile(css`
      margin: 24px 0;
      ${setFontStyle("SubHead2")};
    `)};
  }
`;

export default {
  container,
};
