import styled from "styled-components";

const managementManager = styled.div`
  display: flex;
  margin-top: 24px;
`;

export default {
  managementManager,
};
