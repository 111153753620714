import styled from "styled-components";

const container = styled.div`
  width: 100%;

  .slick-slider {
    overflow: hidden;
    padding: 0 31px;

    .slick-slide {
      display: flex;

      > div {
        margin-right: 8px;
        flex-grow: 1;
        flex-basis: 0;

        &:last-child {
          margin-right: 0px;
        }
      }
    }

    .slick-arrow::before {
      display: none;
    }

    .slick-arrow {
      z-index: 1;
      width: auto;
    }

    .slick-prev {
      left: 0px;
    }

    .slick-next {
      right: 0px;
    }
  }
`;

export default {
  container,
};
