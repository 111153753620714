import SvgIconWrapper from "./SvgIconWrapper";

export default function EllipsisHorizontalIcon(
  props: React.SVGAttributes<SVGElement>
) {
  return (
    <SvgIconWrapper {...props}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_917_7408)">
          <path
            d="M12.6875 20C12.6875 22.1727 10.9248 23.9375 8.75 23.9375C6.57523 23.9375 4.8125 22.1727 4.8125 20C4.8125 17.8273 6.57523 16.0625 8.75 16.0625C10.9248 16.0625 12.6875 17.8273 12.6875 20ZM23.9375 20C23.9375 22.1727 22.1727 23.9375 20 23.9375C17.8273 23.9375 16.0625 22.1727 16.0625 20C16.0625 17.8273 17.8273 16.0625 20 16.0625C22.1727 16.0625 23.9375 17.8273 23.9375 20ZM27.3125 20C27.3125 17.8273 29.0773 16.0625 31.25 16.0625C33.4227 16.0625 35.1875 17.8273 35.1875 20C35.1875 22.1727 33.4227 23.9375 31.25 23.9375C29.0773 23.9375 27.3125 22.1727 27.3125 20Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_917_7408">
            <rect
              width="31.5"
              height="36"
              fill="white"
              transform="translate(4.25 2)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIconWrapper>
  );
}
