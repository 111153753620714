import { useTranslation } from "next-i18next";

import BottomSheet, {
  ButtonInfo,
} from "@sellernote/_sds-v2/src/components/bottomSheet/BottomSheet";

import { NewTeamInfo, TitleAndDesc } from "./index";

export default function BottomSheetForMobile({
  step,
  newTeamInfo,
  opensCreateTeam,
  setOpensCreateTeam,
  titleAndDesc,
  form,
  handleTeamCreate,
  handleAccountSwitch,
  moveStep,
  close,
}: {
  step: number;
  newTeamInfo: NewTeamInfo;
  opensCreateTeam: boolean;
  setOpensCreateTeam: (v: boolean) => void;
  titleAndDesc: TitleAndDesc;
  form: JSX.Element;
  moveStep: (step: number) => void;
  handleTeamCreate: () => void;
  handleAccountSwitch: () => void;
  close: () => void;
}) {
  const { t } = useTranslation(["team"]);

  const mainButtonInfo = (() => {
    if (step === 1)
      return {
        mainButtonInfo: {
          label: t("팀_생성_다음_버튼"),
          onClick: () => moveStep(2),
          disabled: !newTeamInfo.name,
          theme: "primary",
          borderType: "filled",
        } as ButtonInfo,
      };

    if (step === 2)
      return {
        mainButtonInfo: {
          label: t("팀_완료_다음_버튼"),
          onClick: handleTeamCreate,
          theme: "primary",
          borderType: "filled",
        } as ButtonInfo,
      };

    return {
      mainButtonInfo: {
        label: t("팀_바로가기_다음_버튼"),
        onClick: handleAccountSwitch,
        theme: "primary",
        borderType: "filled",
      } as ButtonInfo,
    };
  })();

  return (
    <>
      <BottomSheet
        opensBottomSheet={opensCreateTeam}
        setOpensBottomSheet={setOpensCreateTeam}
        onClose={close}
        {...titleAndDesc}
        {...mainButtonInfo}
      >
        {form}
      </BottomSheet>
    </>
  );
}
