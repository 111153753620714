import SvgIconWrapper from "./SvgIconWrapper";

export default function ExpandAccordionClosedIcon(
  props: React.SVGAttributes<SVGElement>
) {
  return (
    <SvgIconWrapper {...props}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M33 20C33 21.1056 32.1044 22.0006 31 22.0006H22V31.0006C22 32.1063 21.1044 33 20 33C18.8956 33 18 32.1063 18 31.0006V22.0006H9C7.89562 22.0006 7 21.1056 7 20C7 18.8944 7.89562 18.0006 9 18.0006H18V9.00063C18 7.895 18.8956 7 20 7C21.1044 7 22 7.895 22 9.00063V18.0006H31C32.1063 18 33 18.8938 33 20Z"
          fill="currentColor"
        />
      </svg>
    </SvgIconWrapper>
  );
}
