import { useAtom } from "jotai";
import Image from "next/image";

import { CHANNEL_IO_CUSTOM_LAUNCHER_SELECTOR_CLASS_NAME } from "@sellernote/_shared/src/services/chat/channelIO";
import initChatButtonUI from "@sellernote/_shared/src/services/chat/initChatButtonUI";
import openChatModal from "@sellernote/_shared/src/services/chat/openChatModal";
import { COMMON_LAYOUT_ATOMS } from "@sellernote/shipda-kr/src/jotaiStates/common/layout";

import useCheckNeedToOpenChatModal from "../hooks/useCheckNeedToOpenChatModal";

import Styled from "./index.styles";

export default function ChatButton() {
  const [unreadCount, setUnreadCount] = useAtom(
    COMMON_LAYOUT_ATOMS.CHAT_BUTTON_UNREAD_COUNT
  );

  initChatButtonUI(setUnreadCount);

  useCheckNeedToOpenChatModal();

  const hasLargeUnreadCount = !!unreadCount && unreadCount > 9;

  const unreadCountLabel = (() => {
    if (!unreadCount) return "";

    if (hasLargeUnreadCount) return "+9";

    return unreadCount.toString();
  })();

  return (
    <Styled.chatButton
      className={CHANNEL_IO_CUSTOM_LAUNCHER_SELECTOR_CLASS_NAME}
      hasLargeUnreadCount={hasLargeUnreadCount}
      onClick={openChatModal}
    >
      <Image
        src={"/assets/images/mypage/channelIOIcon.webp"}
        alt="logo"
        width={24}
        height={24}
      />

      {!!unreadCount && <div className="count">{unreadCountLabel}</div>}
    </Styled.chatButton>
  );
}
