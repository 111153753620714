import { useRouter } from "next/router";

import { useCheckIsMobile } from "@sellernote/_shared/src/utils/common/hook";
import AlertBanner from "@sellernote/_sds-v2/src/components/AlertBanner";

import Styled from "./index.styles";

export default function ExportationBanner() {
  const { isMobile } = useCheckIsMobile();

  const router = useRouter();

  return (
    <Styled.exportationBanner>
      <AlertBanner
        className="custom-alert-banner"
        label={
          isMobile
            ? "수출 서비스가 오픈되었어요!"
            : "수출 스케줄 및 견적조회 기능이 추가되었습니다! 지금 바로 확인해보세요."
        }
        textButtonProps={{
          theme: "white",
          fontSize: 14,
          label: "수출 견적의뢰하기",
          handleClick: () =>
            router.push("/forwarding/quote?queryStringCase=polSelect&polId=1"),
        }}
      />
    </Styled.exportationBanner>
  );
}
