import { atom } from "recoil";

const KEY_PREFIX = "_shared-for-shipda/forwarding/myPage/atoms";

const SHOWS_MOBILE_NAVIGATION = atom<boolean>({
  key: `${KEY_PREFIX}/SHOWS_MOBILE_NAVIGATION`,
  default: false,
});

const ROUTING_TO = atom<string>({
  key: `${KEY_PREFIX}/ROUTING_TO`,
  default: "",
});

const SHOWS_REMITTANCE_APPLICATION_MODAL = atom<boolean>({
  key: `${KEY_PREFIX}/SHOWS_REMITTANCE_APPLICATION_MODAL`,
  default: false,
});

const SHOWS_REMITTANCE_APPLICATION_MODAL_FOR_MOBILE = atom<boolean>({
  key: `${KEY_PREFIX}/SHOWS_REMITTANCE_APPLICATION_MODAL_FOR_MOBILE`,
  default: false,
});

const IS_REMITTANCE_REGISTER_INFO_COMPLETE = atom<boolean>({
  key: `${KEY_PREFIX}/IS_REGISTER_INFO_INCOMPLETE`,
  default: false,
});

const PREVENTS_ROUTING_AFTER_REMITTANCE_REGISTER = atom<boolean>({
  key: `${KEY_PREFIX}/PREVENTS_ROUTING_AFTER_REMITTANCE_REGISTER`,
  default: false,
});

export default {
  SHOWS_MOBILE_NAVIGATION,
  ROUTING_TO,
  SHOWS_REMITTANCE_APPLICATION_MODAL,
  SHOWS_REMITTANCE_APPLICATION_MODAL_FOR_MOBILE,
  IS_REMITTANCE_REGISTER_INFO_COMPLETE,
  PREVENTS_ROUTING_AFTER_REMITTANCE_REGISTER,
};
