import Image from "next/image";

import { APP_REGION } from "@sellernote/_shared/src/constants";

import Styled from "./index.styles";

export default function ShipdaLogo() {
  return (
    <Styled.logo>
      {APP_REGION === "KR" && (
        <Image
          src={"/assets/images/layout/shipda_logo_white.webp"}
          alt="shipda-logo"
          width={59}
          height={24}
        />
      )}

      {APP_REGION === "SG" && (
        <Image
          src={"/assets/images/layout/bringoodz_logo_white.webp"}
          alt="bringoodz-logo"
          width={101}
          height={24}
        />
      )}
    </Styled.logo>
  );
}
