import { useState } from "react";
import Cookies from "universal-cookie";

export function useCookie(
  /**
   * 쿠키 이름
   */
  cname: string
) {
  const cookies = new Cookies();
  const [cookieValue, setCookieValue] = useState<string>(cookies.get(cname));

  const addCookie = (cvalue: string, remainedDays: number) => {
    const expiredDate = new Date();
    expiredDate.setTime(
      expiredDate.getTime() + remainedDays * 24 * 60 * 60 * 1000
    );
    cookies.set(cname, cvalue, {
      path: "/",
      expires: expiredDate,
    });
    setCookieValue(cookies.get(cname));
  };

  const removeCookie = () => {
    cookies.remove(cname, { path: "/" });
    setCookieValue(cookies.get(cname));
  };

  return { cookieValue, addCookie, removeCookie };
}
