import styled, { css } from "styled-components";

import { mobile } from "@sellernote/_shared/src/styles/functions";

import { LayoutProps } from ".";

const container = styled.div<Pick<LayoutProps, "hasBottomNavigator">>`
  ${mobile(css`
    overflow: hidden;
  `)}

  ${({ hasBottomNavigator }) => {
    if (hasBottomNavigator) {
      return css`
        margin-bottom: 60px;
      `;
    }
  }}
`;

const contentWrapper = styled.div<Pick<LayoutProps, "backgroundColor">>`
  display: flex;
  justify-content: center;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : "#f9f9f9"};
`;

const content = styled.div<
  Pick<LayoutProps, "size" | "minHeight"> & { isTransparentHeader?: boolean }
>`
  min-height: ${({ minHeight }) => (minHeight ? minHeight : "80vh")};

  ${({ size }) => {
    switch (size) {
      case "full": {
        return css`
          width: 100%;

          // 수출 배너로 인해 54px만큼 이동함
          ${mobile(css`
            padding: 102px 0 0;
          `)}
        `;
      }

      default: {
        return css`
          width: 1280px;

          ${mobile(css`
            padding: 60px 15px;
          `)}
        `;
      }
    }
  }}

  //수출 배너로 인해 36px만큼 이동함
  padding-top: ${({ isTransparentHeader }) =>
    isTransparentHeader ? "36px" : "106px"};

  ${mobile(css`
    width: 100%;
  `)}
`;

const chatButton = styled.div<{
  bottomPosition: number;
}>`
  display: inline-block;
  position: fixed;
  bottom: ${({ bottomPosition }) => `${bottomPosition}px`};
  right: 24px;
  z-index: 99;
  cursor: pointer;
  ${mobile(css`
    right: 14px;
  `)}

  /* 채널톡 버튼에서 기본으로 제공하는 UI와 맞춤 (그대로 가져온거라 우리팀의 컨벤션/상수와는 무관하게 사용) */
  > .unread-count {
    z-index: 100;
    position: absolute;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    right: 0;
    color: rgba(255, 255, 255, 0.9);
    background-color: rgb(233, 78, 88);
    top: -6px;
    right: -6px;
    min-width: 24px;
    height: 24px;
    padding: 0px 7px;
    font-size: 14px;
    visibility: visible;
    border-radius: 12px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 2px 1px,
      rgba(0, 0, 0, 0.08) 0px 1px 2px,
      rgba(255, 255, 255, 0.12) 0px 0px 2px inset;

    ${mobile(css`
      top: -7px;
      right: -2px;
      font-size: 13px;
      height: 20px;
      padding: 0px 6px;
      min-width: 20px;
    `)}
  }
`;

const myPageContentContainer = styled.section<{ isMobile: boolean }>`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  padding-top: ${({ isMobile }) => (isMobile ? "48px" : "56px")};
  padding-left: 160px;

  ${mobile(css`
    padding-left: 0px;
  `)}
`;

const footerContainerForMyPage = styled.div<{ isMyPage: boolean }>`
  ${({ isMyPage }) => {
    if (isMyPage) {
      return css`
        width: 100%;
        padding-left: 160px;
        ${mobile(css`
          padding-left: 0px;
        `)}
      `;
    }
  }}
`;

export default {
  container,
  contentWrapper,
  content,
  chatButton,
  myPageContentContainer,
  footerContainerForMyPage,
};
