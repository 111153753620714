import { useSetRecoilState } from "recoil";

import MenuIcon from "@sellernote/_sds-v2/src/components/svgIcons/MenuIcon";
import { COLOR } from "@sellernote/_sds-v2/src/styles/colors";
import { MY_PAGE_ATOMS } from "@sellernote/shipda-kr/src/states/forwarding/myPage";

import Styled from "./index.styles";

export default function HamburgerMenu() {
  const setShowsMobileNavigation = useSetRecoilState(
    MY_PAGE_ATOMS.SHOWS_MOBILE_NAVIGATION
  );

  return (
    <Styled.navigationButton onClick={() => setShowsMobileNavigation(true)}>
      <MenuIcon width={24} height={24} color={COLOR.wh} />
    </Styled.navigationButton>
  );
}
