import styled, { css } from "styled-components";

import { COLOR, TEXT_COLOR } from "../../../styles/colors";
import { setFontStyle } from "../../../styles/typography";

const container = styled.div<{
  width?: string;
  disabled?: boolean;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: ${({ width }) => width || "100%"};

  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "initial")};

  > label {
    display: inline-flex;
    align-items: center;
    margin-bottom: 4px;
    color: ${TEXT_COLOR.black_2};
    ${setFontStyle("Body3")};
    min-width: max-content;

    ${({ disabled }) => {
      if (disabled) {
        return css`
          color: ${TEXT_COLOR.black_disabled};
          cursor: not-allowed;
        `;
      }
    }}
  }
`;

const inputContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  padding: 0 11px;
  width: 100%;
  overflow-y: auto;
  border-radius: 2px;
  border: 1px solid ${COLOR.grayScale_400};
  background-color: ${COLOR.wh};

  &:focus {
    border: 1px solid ${COLOR.primary_900};
  }

  > *:not(:last-child) {
    margin-right: 8px;
  }

  > input {
    flex: 1 0 150px;
    min-width: 150px;
    padding: 11px 0;
    padding-right: 11px;
    background-color: ${COLOR.wh};
    color: ${TEXT_COLOR.black_2};
    ${setFontStyle("Body2")};

    &::placeholder {
      color: ${TEXT_COLOR.black_5};
    }
  }
`;

const chip = styled.div<{ isError: boolean }>`
  display: flex;
  align-items: center;
  color: ${TEXT_COLOR.black_1};
  background-color: ${({ isError }) =>
    isError ? COLOR.error_100 : COLOR.grayScale_100};
  padding: 6px 8px 8px 10px;
  border-radius: 24px;
  word-break: keep-all;
  margin: 8px 0;
  ${setFontStyle("Body3")};

  > svg {
    margin-left: 2px;
    cursor: pointer;
  }
`;

export default {
  container,
  inputContainer,
  chip,
};
