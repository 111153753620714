import styled, { css } from "styled-components";

import { mobile } from "@sellernote/_shared/src/styles/functions";
import { COLOR, TEXT_COLOR } from "@sellernote/_sds-v2/src/styles/colors";
import { setFontStyle } from "@sellernote/_sds-v2/src/styles/typography";

const container = styled.div`
  width: auto;
  height: auto;
  margin-left: auto;
  display: flex;
  align-items: center;

  ${mobile(css`
    margin-right: 4px;
  `)}
`;

const userInfoAndIconContainer = styled.div`
  display: flex;
  align-items: center;
`;

const currentAccountInfo = styled.div`
  margin-right: 16px;
`;

const myPageCurrentAccountInfo = styled.div`
  ${setFontStyle("Body3")};
  color: ${TEXT_COLOR.white_2};
`;

const chatButton = styled.div<{ hasLargeUnreadCount: boolean }>`
  margin-right: 24px;
  cursor: pointer;
  position: relative;

  > .count {
    position: absolute;
    font-family: "Pretendard";
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 9px;
    line-height: 9px;
    border-radius: 100px;
    background: ${COLOR.point_400};
    color: ${TEXT_COLOR.white_1};

    ${({ hasLargeUnreadCount }) => {
      if (hasLargeUnreadCount) {
        return css`
          top: -1px;
          right: -7px;
          width: 17px;
          height: 12px;
        `;
      }

      return css`
        top: -1px;
        right: -6px;
        width: 12px;
        height: 12px;
      `;
    }}
  }
`;

export default {
  container,
  userInfoAndIconContainer,
  currentAccountInfo,
  myPageCurrentAccountInfo,
  chatButton,
};
