import { APP_REGION } from "@sellernote/_shared/src/constants";

import ManagerList from "../components/ManagerList";

import { ShipdaService } from "../../..";
import MANAGER_RECORD from "../constants/MANAGER_RECORD";
import Styled from "./index.styles";

export default function Management({
  serviceType,
}: {
  serviceType: ShipdaService;
}) {
  return (
    <Styled.managementManager>
      <ManagerList
        managerList={MANAGER_RECORD[APP_REGION][serviceType]["management"]}
      />
    </Styled.managementManager>
  );
}
