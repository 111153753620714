import styled, { css } from "styled-components";

import { mobile } from "@sellernote/_shared/src/styles/functions";

import { COLOR, TEXT_COLOR } from "../../styles/colors";
import { setFontStyle } from "../../styles/typography";

import { AlertType } from ".";

const container = styled.div<{ type: AlertType }>`
  padding: 8px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background-color: ${({ type }) =>
    type === "Point" ? COLOR.point_400 : COLOR.grayScale_700};

  > .alert-banner-label {
    ${setFontStyle("SubHead3")};
    color: ${TEXT_COLOR.white_1};
    word-break: keep-all;
  }

  & + & {
    margin-top: 2px;
  }

  ${mobile(css`
    width: 100%;
    flex-direction: column;
    gap: 4px;
    text-align: center;
  `)};
`;

export default {
  container,
};
