import { useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";

import TEAM_QUERY from "@sellernote/_shared/src/queries/forwarding/TEAM_QUERY";
import { FORWARDING_AUTH_ATOMS } from "@sellernote/_shared/src/states/forwarding/auth";
import { TEAM_ATOMS } from "@sellernote/shipda-kr/src/states/forwarding/team";

/**
 * auth refresh 되는 경우, 초대 내역이 있는지 확인 후 팀 초대 모달을 띄우는 hook
 */
export default function useCheckInvitationTeamListWhenTokenRefreshed() {
  const loggedIn = useRecoilValue(FORWARDING_AUTH_ATOMS.USER_LOGGED_IN);

  const needCheckTeamInvitation =
    typeof window !== "undefined" &&
    window.localStorage.getItem("needCheckTeamInvitation");

  const setInvitationTeamList = useSetRecoilState(TEAM_ATOMS.INVITED_TEAM_LIST);

  const { refetch: refetchInvitationTeamList } =
    TEAM_QUERY.useGetInvitedTeamList({
      enabled: false,
      onSuccess: (invitedTeamList) => {
        if (!invitedTeamList.length) return;

        setInvitationTeamList(invitedTeamList);
      },

      onSettled: () => {
        window.localStorage.setItem("needCheckTeamInvitation", "FALSE");
      },
    });

  useEffect(() => {
    if (!loggedIn)
      return window.localStorage.setItem("needCheckTeamInvitation", "FALSE");

    if (needCheckTeamInvitation !== "TRUE") return;

    refetchInvitationTeamList();
  }, [loggedIn, needCheckTeamInvitation, refetchInvitationTeamList]);

  return {};
}
