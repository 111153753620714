import styled, { css } from "styled-components";

import { InputTextFiledSize } from "@sellernote/_shared/src/headlessComponents/form/useInputTextField";

import { COLOR, TEXT_COLOR } from "../../../styles/colors";
import { setFontStyle } from "../../../styles/typography";

const container = styled.div<{
  width?: string;
  disabled?: boolean;
  isHorizontalLabel?: boolean;
  hasError: boolean;
  widthOfButtonAddon: number | undefined;
  labelMinWidth?: string;
  size: InputTextFiledSize;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: ${({ width }) => width || "100%"};

  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "initial")};

  ${({ isHorizontalLabel }) => {
    if (isHorizontalLabel) {
      return css`
        flex-direction: row;
      `;
    }
  }}

  > label {
    display: inline-flex;
    align-items: center;

    ${({ isHorizontalLabel, size }) => {
      if (isHorizontalLabel) {
        if (size === "default") {
          return css`
            margin-top: 14px;
          `;
        }

        if (size === "small") {
          return css`
            margin-top: 10px;
          `;
        }

        if (size === "extraSmall") {
          return css`
            margin-top: 6px;
          `;
        }
      }

      return css`
        margin-bottom: 4px;
      `;
    }};

    color: ${({ size }) => {
      if (size === "default") {
        return TEXT_COLOR.black_2;
      }

      return TEXT_COLOR.black_3;
    }};
    ${setFontStyle("Body3")};
    min-width: ${({ labelMinWidth }) =>
      labelMinWidth ? labelMinWidth : " max-content"};

    ${({ isHorizontalLabel, labelMinWidth }) => {
      if (isHorizontalLabel && !labelMinWidth) {
        return css`
          padding-right: 80px;
        `;
      }
    }}

    ${({ disabled }) => {
      if (disabled) {
        return css`
          color: ${TEXT_COLOR.black_disabled};
          cursor: not-allowed;
        `;
      }
    }}

    > .tooltip {
      margin-left: 2px;
    }
  }

  .input-and-error-container {
    width: 100%;
    position: relative;
  }

  .input-container {
    > input {
      padding: ${({ size }) => {
        if (size === "small") {
          return "10px 12px";
        }

        if (size === "extraSmall") {
          return "6px 10px";
        }

        return "11px 12px";
      }};
      padding-right: ${({ widthOfButtonAddon }) =>
        // TODO: (2023-08-17 피그마 기준) Default Size에만 Addon이 있음, 다른 사이즈에도 추가될 경우 조건 추가
        widthOfButtonAddon ? `${widthOfButtonAddon + 12 + 8}px` : "12px"};
      width: 100%;
      border-radius: 2px;
      border: 1px solid ${COLOR.grayScale_400};
      background-color: ${COLOR.wh};
      color: ${TEXT_COLOR.black_2};
      ${({ size }) => {
        if (size === "default") {
          return setFontStyle("Body2");
        }

        return setFontStyle("Body4");
      }};
      background-color: ${COLOR.wh};

      ${({ hasError }) => {
        if (hasError) {
          return css`
            border: 1px solid ${COLOR.error_200};
          `;
        }
      }};

      &:user-invalid {
        border: 1px solid ${COLOR.error_200};
      }

      ${({ disabled }) => {
        if (disabled) {
          return css`
            color: ${TEXT_COLOR.black_disabled};
            background-color: ${COLOR.grayScale_100};
            border: 1px solid ${COLOR.grayScale_300};
            cursor: not-allowed;
          `;
        }
      }}

      &:focus {
        border: 1px solid ${COLOR.primary_900};

        &:user-invalid {
          border: 1px solid ${COLOR.error_200};
        }

        ${({ hasError }) => {
          if (hasError) {
            return css`
              border: 1px solid ${COLOR.error_200};
            `;
          }
        }}

        ${({ disabled }) => {
          if (disabled) {
            return css`
              border: 1px solid ${COLOR.grayScale_300};
            `;
          }
        }};
      }

      &::placeholder {
        color: ${TEXT_COLOR.black_5};
      }
    }
  }

  .error-message {
    margin-top: 2px;
    color: ${COLOR.error_400};
    ${setFontStyle("Body5")};
  }
`;

const addon = styled.div<{
  size: InputTextFiledSize;
}>`
  position: absolute;

  &.error {
    top: ${({ size }) => {
      if (size === "default") {
        return "16px";
      }

      if (size === "small") {
        return "12px";
      }

      if (size === "extraSmall") {
        return "8px";
      }
    }};
    right: 8px;
  }

  &.clear {
    top: ${({ size }) => {
      if (size === "default") {
        return "16px";
      }

      if (size === "small") {
        return "12px";
      }

      if (size === "extraSmall") {
        return "8px";
      }
    }};
    right: 8px;
    cursor: pointer;
  }

  &.button-container {
    display: flex;
    align-items: center;
    top: 8px;
    right: 8px;

    > .extra-label {
      margin-right: 4px;
      color: ${COLOR.primary_600};
      ${setFontStyle("Body4")};
    }
  }
`;

export default {
  container,
  addon,
};
