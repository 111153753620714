import SvgIconWrapper from "./SvgIconWrapper";

export default function MenuIcon(props: React.SVGAttributes<SVGElement>) {
  return (
    <SvgIconWrapper {...props}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_917_7388)">
          <path
            d="M4.25 8.75C4.25 7.50758 5.25758 6.5 6.5 6.5H33.5C34.7445 6.5 35.75 7.50758 35.75 8.75C35.75 9.99453 34.7445 11 33.5 11H6.5C5.25758 11 4.25 9.99453 4.25 8.75ZM4.25 20C4.25 18.7555 5.25758 17.75 6.5 17.75H33.5C34.7445 17.75 35.75 18.7555 35.75 20C35.75 21.2445 34.7445 22.25 33.5 22.25H6.5C5.25758 22.25 4.25 21.2445 4.25 20ZM33.5 33.5H6.5C5.25758 33.5 4.25 32.4945 4.25 31.25C4.25 30.0055 5.25758 29 6.5 29H33.5C34.7445 29 35.75 30.0055 35.75 31.25C35.75 32.4945 34.7445 33.5 33.5 33.5Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_917_7388">
            <rect
              width="31.5"
              height="36"
              fill="white"
              transform="translate(4.25 2)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIconWrapper>
  );
}
