import styled from "styled-components";

import { TEXT_COLOR } from "@sellernote/_sds-v2/src/styles/colors";
import {
  setFontStyle,
  TypeLevel,
} from "@sellernote/_sds-v2/src/styles/typography";

const userIconButton = styled.button<{
  backgroundColor: string;
  size: number;
  disabled: boolean;
}>`
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  border-radius: 100%;
  position: relative;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  border: none;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

const userName = styled.p<{ size: number; fontStyle: TypeLevel }>`
  color: ${TEXT_COLOR.white_1};
  ${({ fontStyle }) => setFontStyle(fontStyle)};
  position: absolute;
  width: ${({ size }) => `${size}px`};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export default { userIconButton, userName };
