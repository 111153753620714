import styled from "styled-components";

import { setFontStyle } from "@sellernote/_sds-v2/src/styles/typography";

const supportAndTermsMenuContainer = styled.nav`
  ul {
    display: flex;

    > li {
      display: flex;
      margin-right: 8px;
      ${setFontStyle("Body4")};

      &::after {
        display: block;
        content: "･";
        margin-left: 8px;
      }

      &:last-child::after {
        display: none;
      }
    }

    a {
      ${setFontStyle("Body4")};
    }
  }

  .chat-trigger {
    cursor: pointer;
  }
`;

export default {
  supportAndTermsMenuContainer,
};
