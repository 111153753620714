import SvgIconWrapper from "./SvgIconWrapper";

export default function ExpandAccordionOpenIcon(
  props: React.SVGAttributes<SVGElement>
) {
  return (
    <SvgIconWrapper {...props}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M31 22H9C7.89437 22 7 21.105 7 19.9994C7 18.8938 7.89437 18 9 18H31C32.1056 18 33 18.8938 33 19.9994C33 21.105 32.1063 22 31 22Z"
          fill="currentColor"
        />
      </svg>
    </SvgIconWrapper>
  );
}
