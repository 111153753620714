import styled, { css } from "styled-components";

import { mobile } from "@sellernote/_shared/src/styles/functions";
import { COLOR, TEXT_COLOR } from "@sellernote/_sds-v2/src/styles/colors";
import { setFontStyle } from "@sellernote/_sds-v2/src/styles/typography";

const container = styled.div`
  display: flex;
  align-items: center;

  ${mobile(css`
    align-items: center;
  `)}

  > svg {
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      background-color: ${COLOR.bgColor_2};
    }

    &.disabled:hover {
      background-color: transparent;
      cursor: not-allowed;
    }
  }

  .move-left-button {
    ${mobile(css`
      margin-right: 24px;
    `)}
  }

  .move-right-button {
    ${mobile(css`
      margin-left: 24px;
    `)}
  }

  /* 디자이너의 요청으로 개별 사이즈 설정 */
  .input-text-field input {
    padding-top: 7px;
    padding-bottom: 7px;
  }
`;

const pageIndicator = styled.div`
  display: flex;
  align-items: center;
  margin: 0 16px;
`;

const pageNumber = styled.div<{ moreThanThreeDigits?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  ${setFontStyle("Body2")};
  color: ${TEXT_COLOR.black_2};
  cursor: pointer;
  border-radius: 50%;

  &:hover {
    background-color: ${COLOR.bgColor_2};
  }

  &.selected {
    color: ${TEXT_COLOR.white_1};
    background-color: ${COLOR.grayScale_600};
  }

  &:not(:last-child) {
    margin-right: ${({ moreThanThreeDigits }) =>
      moreThanThreeDigits ? "8px" : "0"};
  }
`;

const moveToFirstPage = styled.div`
  display: flex;
  align-items: center;
  margin-left: 24px;

  svg {
    margin-left: 16px;
  }
`;

const moveToLastPage = styled.div`
  display: flex;
  align-items: center;
  margin-right: 24px;

  svg {
    margin-right: 16px;
  }
`;

const mobileContainer = styled.div`
  display: flex;
  align-items: center;

  .mobile-total-page {
    display: block;
    margin-left: 16px;
    word-break: keep-all;
    ${setFontStyle("Body2")};
    color: ${TEXT_COLOR.black_3};
  }

  .slash {
    margin-right: 8px;
  }
`;

export default {
  container,
  pageIndicator,
  pageNumber,
  moveToFirstPage,
  moveToLastPage,
  mobileContainer,
};
