import { useCheckIsMobile } from "@sellernote/_shared/src/utils/common/hook";

import { ManagerInfoProps } from "../../../..";
import ManagerListForDesktop from "./ManagerListForDesktop";
import ManagerListForMobile from "./ManagerListForMobile";

export default function ManagerList({
  managerList,
}: {
  managerList: ManagerInfoProps[];
}) {
  const { isMobile } = useCheckIsMobile();

  return isMobile ? (
    <ManagerListForMobile managerList={managerList} />
  ) : (
    <ManagerListForDesktop managerList={managerList} />
  );
}
