import styled, { css } from "styled-components";

import { COLOR, TEXT_COLOR } from "@sellernote/_sds-v2/src/styles/colors";
import { setFontStyle } from "@sellernote/_sds-v2/src/styles/typography";

const container = styled.div`
  width: 328px;
  height: 54px;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid ${COLOR.grayScale_400};
  display: flex;
  align-items: center;
`;

const forwardingTab = styled.div<{ isActive: boolean }>`
  width: 156px;
  height: 38px;
  background: gray;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
  background: ${COLOR.wh};

  ${setFontStyle("SubHead2")};

  ${({ isActive }) =>
    isActive &&
    css`
      background: ${COLOR.grayScale_600};
      color: ${TEXT_COLOR.white_1};
    `}
`;

const fulfillmentTab = styled.div<{ isActive: boolean }>`
  width: 156px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;

  ${setFontStyle("SubHead2")};

  ${({ isActive }) =>
    isActive &&
    css`
      background: ${COLOR.grayScale_600};
      color: ${TEXT_COLOR.white_1};
    `}
`;

export default {
  container,
  forwardingTab,
  fulfillmentTab,
};
