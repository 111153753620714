import React, { useEffect } from "react";
import Router from "next/router";
import { useTranslation } from "next-i18next";
import { useRecoilState } from "recoil";

import Loading from "@sellernote/_shared/src/componentsToMoveToV1/Loading";
import { FORWARDING_AUTH_ATOMS } from "@sellernote/_shared/src/states/forwarding/auth";

export default function withRequireAuth(
  WrappedComponent: React.ComponentType<unknown>
) {
  return () => {
    const { t } = useTranslation(["common"]);

    const [loggedIn, setLoggedIn] = useRecoilState(
      FORWARDING_AUTH_ATOMS.USER_LOGGED_IN
    );

    // url로 direct로 들어왔을때를 위함
    useEffect(() => {
      const accessToken =
        window.localStorage.getItem("accessToken") ||
        window.sessionStorage.getItem("accessToken");

      if (accessToken) {
        setLoggedIn(true);
      } else {
        setLoggedIn(false);
      }
    }, [setLoggedIn]);

    useEffect(() => {
      if (typeof loggedIn === "boolean" && !loggedIn) {
        const backTo = window?.history?.state?.as;
        if (backTo) {
          Router.push(`/login?backTo=${backTo}`);
        } else {
          Router.push(`/login`);
        }
      }
    }, [loggedIn]);

    if (loggedIn) {
      return <WrappedComponent />;
    } else {
      return <Loading active={true} label={t("common:loading")} />;
    }
  };
}
