import SvgIconWrapper from "./SvgIconWrapper";

export default function CallSolidIcon(props: React.SVGAttributes<SVGElement>) {
  return (
    <SvgIconWrapper {...props}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_917_7357)">
          <path
            d="M37.9438 29.2108L36.309 36.2983C36.0793 37.3003 35.1987 38.0013 34.1673 38.0013C16.4281 37.9999 2 23.5718 2 5.83195C2 4.80117 2.70095 3.91946 3.70297 3.69094L10.7905 2.05618C11.8227 1.81697 12.8773 2.35275 13.3063 3.33024L16.5772 10.9591C16.9595 11.8577 16.7016 12.9047 15.9458 13.522L12.1602 16.5616C14.5494 21.4287 18.5066 25.3858 23.375 27.7765L26.4744 23.9937C27.0852 23.2357 28.1401 22.9734 29.0387 23.3626L36.6676 26.6328C37.5852 27.1155 38.1828 28.1843 37.9438 29.2108Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_917_7357">
            <rect
              width="36"
              height="36"
              fill="white"
              transform="translate(2 2)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIconWrapper>
  );
}
