import SvgIconWrapper from "./SvgIconWrapper";

export default function SubmenuBookIcon(
  props: React.SVGAttributes<SVGElement>
) {
  return (
    <SvgIconWrapper {...props}>
      <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.52023 6.96771H1.87961C1.71167 6.96782 1.54539 7.00101 1.39027 7.06537C1.23515 7.12974 1.09423 7.22403 0.975554 7.34286C0.856877 7.46169 0.762767 7.60274 0.698597 7.75794C0.634427 7.91314 0.601455 8.07946 0.601563 8.2474V22.0527C0.601412 22.2207 0.634355 22.387 0.698509 22.5422C0.762663 22.6974 0.856772 22.8385 0.975458 22.9573C1.09414 23.0762 1.23508 23.1705 1.39022 23.2348C1.54536 23.2992 1.71165 23.3323 1.87961 23.3324H3.52023C3.35228 23.3323 3.18598 23.2992 3.03084 23.2348C2.87571 23.1705 2.73477 23.0762 2.61608 22.9573C2.4974 22.8385 2.40329 22.6974 2.33913 22.5422C2.27498 22.387 2.24204 22.2207 2.24219 22.0527V8.2474C2.24208 8.07946 2.27505 7.91314 2.33922 7.75794C2.40339 7.60274 2.4975 7.46169 2.61618 7.34286C2.73486 7.22403 2.87578 7.12974 3.0309 7.06537C3.18601 7.00101 3.35229 6.96782 3.52023 6.96771Z"
          fill="#D4E1F4"
        />
        <path
          d="M10.4212 9.43115H5.71045C5.62343 9.43115 5.53997 9.46572 5.47843 9.52726C5.41689 9.58879 5.38232 9.67225 5.38232 9.75928C5.38232 9.8463 5.41689 9.92976 5.47843 9.9913C5.53997 10.0528 5.62343 10.0874 5.71045 10.0874H10.4212C10.5083 10.0874 10.5917 10.0528 10.6533 9.9913C10.7148 9.92976 10.7494 9.8463 10.7494 9.75928C10.7494 9.67225 10.7148 9.58879 10.6533 9.52726C10.5917 9.46572 10.5083 9.43115 10.4212 9.43115Z"
          fill="#475FB0"
        />
        <path
          d="M3.24756 10.0874H4.28372C4.37075 10.0874 4.45421 10.0528 4.51574 9.9913C4.57728 9.92976 4.61185 9.8463 4.61185 9.75928C4.61185 9.67225 4.57728 9.58879 4.51574 9.52726C4.45421 9.46572 4.37075 9.43115 4.28372 9.43115H3.24756C3.16053 9.43115 3.07707 9.46572 3.01554 9.52726C2.954 9.58879 2.91943 9.67225 2.91943 9.75928C2.91943 9.8463 2.954 9.92976 3.01554 9.9913C3.07707 10.0528 3.16053 10.0874 3.24756 10.0874Z"
          fill="#475FB0"
        />
        <path
          d="M10.421 12.9077H9.00146C8.91444 12.9077 8.83098 12.9422 8.76945 13.0038C8.70791 13.0653 8.67334 13.1488 8.67334 13.2358C8.67334 13.3228 8.70791 13.4063 8.76945 13.4678C8.83098 13.5293 8.91444 13.5639 9.00146 13.5639H10.4211C10.5081 13.5639 10.5916 13.5293 10.6531 13.4678C10.7147 13.4063 10.7492 13.3228 10.7492 13.2358C10.7492 13.1488 10.7147 13.0653 10.6531 13.0038C10.5916 12.9422 10.5081 12.9077 10.4211 12.9077H10.421Z"
          fill="#475FB0"
        />
        <path
          d="M3.24756 13.5639H7.59735C7.68437 13.5639 7.76783 13.5293 7.82937 13.4678C7.8909 13.4063 7.92547 13.3228 7.92547 13.2358C7.92547 13.1488 7.8909 13.0653 7.82937 13.0038C7.76783 12.9422 7.68437 12.9077 7.59735 12.9077H3.24756C3.16053 12.9077 3.07707 12.9422 3.01554 13.0038C2.954 13.0653 2.91943 13.1488 2.91943 13.2358C2.91943 13.3228 2.954 13.4063 3.01554 13.4678C3.07707 13.5293 3.16053 13.5639 3.24756 13.5639Z"
          fill="#475FB0"
        />
        <path
          d="M10.4211 16.3843H6.09766C6.01063 16.3843 5.92717 16.4189 5.86564 16.4804C5.8041 16.542 5.76953 16.6254 5.76953 16.7125C5.76953 16.7995 5.8041 16.8829 5.86564 16.9445C5.92717 17.006 6.01063 17.0406 6.09766 17.0406H10.421C10.508 17.0406 10.5915 17.006 10.653 16.9445C10.7145 16.8829 10.7491 16.7995 10.7491 16.7125C10.7491 16.6254 10.7145 16.542 10.653 16.4804C10.5915 16.4189 10.508 16.3843 10.421 16.3843H10.4211Z"
          fill="#475FB0"
        />
        <path
          d="M3.24756 17.0406H4.67687C4.7639 17.0406 4.84736 17.006 4.90889 16.9445C4.97043 16.8829 5.005 16.7995 5.005 16.7125C5.005 16.6254 4.97043 16.542 4.90889 16.4804C4.84736 16.4189 4.7639 16.3843 4.67687 16.3843H3.24756C3.16053 16.3843 3.07707 16.4189 3.01554 16.4804C2.954 16.542 2.91943 16.6254 2.91943 16.7125C2.91943 16.7995 2.954 16.8829 3.01554 16.9445C3.07707 17.006 3.16053 17.0406 3.24756 17.0406Z"
          fill="#475FB0"
        />
        <path
          d="M10.4212 19.8607H8.81885C8.73182 19.8607 8.64836 19.8952 8.58683 19.9568C8.52529 20.0183 8.49072 20.1018 8.49072 20.1888C8.49072 20.2758 8.52529 20.3593 8.58683 20.4208C8.64836 20.4823 8.73182 20.5169 8.81885 20.5169H10.4212C10.5082 20.5169 10.5917 20.4823 10.6532 20.4208C10.7147 20.3593 10.7493 20.2758 10.7493 20.1888C10.7493 20.1018 10.7147 20.0183 10.6532 19.9568C10.5917 19.8952 10.5082 19.8607 10.4212 19.8607Z"
          fill="#475FB0"
        />
        <path
          d="M3.24756 20.5169H7.42847C7.5155 20.5169 7.59896 20.4823 7.66049 20.4208C7.72203 20.3593 7.7566 20.2758 7.7566 20.1888C7.7566 20.1018 7.72203 20.0183 7.66049 19.9568C7.59896 19.8952 7.5155 19.8607 7.42847 19.8607H3.24756C3.16053 19.8607 3.07707 19.8952 3.01554 19.9568C2.954 20.0183 2.91943 20.1018 2.91943 20.1888C2.91943 20.2758 2.954 20.3593 3.01554 20.4208C3.07707 20.4823 3.16053 20.5169 3.24756 20.5169Z"
          fill="#475FB0"
        />
        <path
          d="M17.6089 9.75928C17.6089 9.8463 17.6435 9.92976 17.705 9.9913C17.7665 10.0528 17.85 10.0874 17.937 10.0874H20.123C20.21 10.0874 20.2935 10.0528 20.355 9.9913C20.4165 9.92976 20.4511 9.8463 20.4511 9.75928C20.4511 9.67225 20.4165 9.58879 20.355 9.52726C20.2935 9.46572 20.21 9.43115 20.123 9.43115H17.937C17.85 9.43115 17.7665 9.46572 17.705 9.52726C17.6435 9.58879 17.6089 9.67225 17.6089 9.75928Z"
          fill="#475FB0"
        />
        <path
          d="M15.7566 10.0874H16.5277C16.6147 10.0874 16.6982 10.0528 16.7597 9.9913C16.8212 9.92976 16.8558 9.8463 16.8558 9.75928C16.8558 9.67225 16.8212 9.58879 16.7597 9.52726C16.6982 9.46572 16.6147 9.43115 16.5277 9.43115H15.7566C15.6696 9.43115 15.5861 9.46572 15.5246 9.52726C15.463 9.58879 15.4285 9.67225 15.4285 9.75928C15.4285 9.8463 15.463 9.92976 15.5246 9.9913C15.5861 10.0528 15.6696 10.0874 15.7566 10.0874Z"
          fill="#475FB0"
        />
        <path
          d="M20.9277 13.5639H22.93C23.017 13.5639 23.1005 13.5293 23.162 13.4678C23.2236 13.4063 23.2581 13.3228 23.2581 13.2358C23.2581 13.1488 23.2236 13.0653 23.162 13.0038C23.1005 12.9422 23.017 12.9077 22.93 12.9077H20.9277C20.8407 12.9077 20.7573 12.9422 20.6957 13.0038C20.6342 13.0653 20.5996 13.1488 20.5996 13.2358C20.5996 13.3228 20.6342 13.4063 20.6957 13.4678C20.7573 13.5293 20.8407 13.5639 20.9277 13.5639Z"
          fill="#475FB0"
        />
        <path
          d="M15.7566 13.5639H19.48C19.5671 13.5639 19.6505 13.5293 19.7121 13.4678C19.7736 13.4063 19.8082 13.3228 19.8082 13.2358C19.8082 13.1488 19.7736 13.0653 19.7121 13.0038C19.6505 12.9422 19.5671 12.9077 19.48 12.9077H15.7566C15.6696 12.9077 15.5861 12.9422 15.5246 13.0038C15.463 13.0653 15.4285 13.1488 15.4285 13.2358C15.4285 13.3228 15.463 13.4063 15.5246 13.4678C15.5861 13.5293 15.6696 13.5639 15.7566 13.5639Z"
          fill="#475FB0"
        />
        <path
          d="M22.9299 16.3843H18.2683C18.1813 16.3843 18.0978 16.4189 18.0363 16.4804C17.9748 16.542 17.9402 16.6254 17.9402 16.7125C17.9402 16.7995 17.9748 16.8829 18.0363 16.9445C18.0978 17.006 18.1813 17.0406 18.2683 17.0406H22.9299C23.017 17.0406 23.1004 17.006 23.1619 16.9445C23.2235 16.8829 23.2581 16.7995 23.2581 16.7125C23.2581 16.6254 23.2235 16.542 23.1619 16.4804C23.1004 16.4189 23.017 16.3843 22.9299 16.3843Z"
          fill="#475FB0"
        />
        <path
          d="M15.7566 17.0406H16.8728C16.9598 17.0406 17.0432 17.006 17.1048 16.9445C17.1663 16.8829 17.2009 16.7995 17.2009 16.7125C17.2009 16.6254 17.1663 16.542 17.1048 16.4804C17.0432 16.4189 16.9598 16.3843 16.8728 16.3843H15.7566C15.6696 16.3843 15.5861 16.4189 15.5246 16.4804C15.463 16.542 15.4285 16.6254 15.4285 16.7125C15.4285 16.7995 15.463 16.8829 15.5246 16.9445C15.5861 17.006 15.6696 17.0406 15.7566 17.0406Z"
          fill="#475FB0"
        />
        <path
          d="M22.93 19.8607H21.8765C21.7894 19.8607 21.706 19.8952 21.6444 19.9568C21.5829 20.0183 21.5483 20.1018 21.5483 20.1888C21.5483 20.2758 21.5829 20.3593 21.6444 20.4208C21.706 20.4823 21.7894 20.5169 21.8765 20.5169H22.93C23.017 20.5169 23.1005 20.4823 23.162 20.4208C23.2236 20.3593 23.2581 20.2758 23.2581 20.1888C23.2581 20.1018 23.2236 20.0183 23.162 19.9568C23.1005 19.8952 23.017 19.8607 22.93 19.8607Z"
          fill="#475FB0"
        />
        <path
          d="M15.7566 20.5169H20.4864C20.5734 20.5169 20.6569 20.4823 20.7184 20.4208C20.78 20.3593 20.8145 20.2758 20.8145 20.1888C20.8145 20.1018 20.78 20.0183 20.7184 19.9568C20.6569 19.8952 20.5734 19.8607 20.4864 19.8607H15.7566C15.6696 19.8607 15.5861 19.8952 15.5246 19.9568C15.463 20.0183 15.4285 20.1018 15.4285 20.1888C15.4285 20.2758 15.463 20.3593 15.5246 20.4208C15.5861 20.4823 15.6696 20.5169 15.7566 20.5169Z"
          fill="#475FB0"
        />
        <path
          d="M2.31323 9.02551C2.31323 8.93849 2.27866 8.85503 2.21713 8.79349C2.15559 8.73196 2.07213 8.69739 1.98511 8.69739C1.89808 8.69739 1.81462 8.73196 1.75309 8.79349C1.69155 8.85503 1.65698 8.93849 1.65698 9.02551V9.36977C1.65698 9.45679 1.69155 9.54025 1.75309 9.60179C1.81462 9.66333 1.89808 9.6979 1.98511 9.6979C2.07213 9.6979 2.15559 9.66333 2.21713 9.60179C2.27866 9.54025 2.31323 9.45679 2.31323 9.36977V9.02551Z"
          fill="#475FB0"
        />
        <path
          d="M1.98511 10.2994C1.89808 10.2994 1.81462 10.334 1.75309 10.3955C1.69155 10.4571 1.65698 10.5405 1.65698 10.6276V14.0729C1.65698 14.1599 1.69155 14.2434 1.75309 14.3049C1.81462 14.3664 1.89808 14.401 1.98511 14.401C2.07213 14.401 2.15559 14.3664 2.21713 14.3049C2.27866 14.2434 2.31323 14.1599 2.31323 14.0729V10.6276C2.31323 10.5405 2.27866 10.4571 2.21713 10.3955C2.15559 10.334 2.07213 10.2994 1.98511 10.2994Z"
          fill="#475FB0"
        />
        <path
          d="M25.3955 22.0529C25.3954 22.305 25.2953 22.5468 25.1172 22.7252C24.9391 22.9036 24.6974 23.0041 24.4453 23.0045H14.6045C14.4674 23.0045 14.3356 23.0581 14.2375 23.1539L13.5461 23.8277L13.4372 21.1386C13.4368 21.0955 13.4279 21.053 13.411 21.0133C13.3941 20.9737 13.3696 20.9377 13.3388 20.9076C13.308 20.8774 13.2716 20.8536 13.2316 20.8375C13.1916 20.8214 13.1489 20.8133 13.1058 20.8138C13.0627 20.8142 13.0201 20.8231 12.9805 20.84C12.9408 20.8569 12.9049 20.8814 12.8747 20.9122C12.8446 20.943 12.8208 20.9794 12.8047 21.0194C12.7886 21.0593 12.7805 21.1021 12.781 21.1452L12.8896 23.8125L12.214 23.1538C12.1158 23.0581 11.9841 23.0045 11.847 23.0045H1.87977C1.62769 23.004 1.38608 22.9036 1.20797 22.7252C1.02985 22.5468 0.929774 22.305 0.929688 22.0529V8.24752C0.929788 7.99544 1.02987 7.75369 1.20798 7.5753C1.38609 7.39691 1.62769 7.29645 1.87977 7.29596H11.3558C11.7229 7.29655 12.0748 7.44286 12.334 7.70274C12.5933 7.96262 12.7388 8.3148 12.7386 8.6819L12.7593 18.9577C12.7601 19.0447 12.7955 19.1278 12.8577 19.1887C12.9198 19.2497 13.0036 19.2834 13.0907 19.2825C13.1777 19.2817 13.2608 19.2463 13.3217 19.1841C13.3826 19.122 13.4164 19.0381 13.4155 18.9511L13.3948 8.67862H13.3967C13.3971 8.31205 13.5429 7.9606 13.8021 7.7014C14.0613 7.44219 14.4128 7.29638 14.7794 7.29596H20.0755V6.63971H14.7794C14.4388 6.6399 14.1037 6.72542 13.8047 6.88844C13.5057 7.05146 13.2523 7.28679 13.0676 7.57295C12.883 7.28681 12.6296 7.05148 12.3306 6.88846C12.0316 6.72544 11.6965 6.63992 11.3559 6.63971H1.87977C1.4537 6.6404 1.04531 6.81007 0.744168 7.11148C0.44303 7.4129 0.273741 7.82145 0.273438 8.24752V22.0529C0.273727 22.479 0.443011 22.8876 0.744152 23.189C1.04529 23.4904 1.4537 23.6601 1.87977 23.6608H11.7941L12.8587 24.6986C12.9565 24.7951 13.0884 24.8492 13.2258 24.8492C13.3632 24.8492 13.4951 24.7951 13.593 24.6986L14.6575 23.6608H24.4453C24.8714 23.6601 25.2798 23.4904 25.5809 23.189C25.8821 22.8876 26.0514 22.479 26.0516 22.0529V10.2211H25.3954L25.3955 22.0529Z"
          fill="#475FB0"
        />
        <path
          d="M23.7349 6.25684C23.6478 6.25684 23.5644 6.29141 23.5028 6.35294C23.4413 6.41448 23.4067 6.49794 23.4067 6.58496V8.98295C23.4067 9.06998 23.4413 9.15344 23.5028 9.21497C23.5644 9.27651 23.6478 9.31108 23.7349 9.31108C23.8219 9.31108 23.9053 9.27651 23.9669 9.21497C24.0284 9.15344 24.063 9.06998 24.063 8.98295V6.58496C24.063 6.49794 24.0284 6.41448 23.9669 6.35294C23.9053 6.29141 23.8219 6.25684 23.7349 6.25684Z"
          fill="#54C3D6"
        />
        <path
          d="M23.7348 5.18805C23.6542 5.18804 23.5754 5.21192 23.5084 5.25667C23.4415 5.30142 23.3892 5.36504 23.3584 5.43946C23.3276 5.51389 23.3195 5.5958 23.3352 5.67481C23.3509 5.75383 23.3897 5.82641 23.4467 5.88338C23.5037 5.94034 23.5762 5.97914 23.6553 5.99485C23.7343 6.01057 23.8162 6.00249 23.8906 5.97166C23.965 5.94082 24.0286 5.88861 24.0734 5.82162C24.1181 5.75463 24.142 5.67587 24.142 5.59531C24.1419 5.48733 24.099 5.38381 24.0226 5.30746C23.9463 5.23111 23.8427 5.18817 23.7348 5.18805Z"
          fill="#54C3D6"
        />
        <path
          d="M23.597 3.15432C22.8817 3.17861 22.1861 3.3949 21.5832 3.78052C20.9803 4.16613 20.4922 4.70687 20.1701 5.34602C19.848 5.98517 19.7039 6.6992 19.7527 7.41325C19.8015 8.12729 20.0416 8.81504 20.4477 9.40439C20.4978 9.47397 20.5732 9.52115 20.6577 9.5358C20.7422 9.55045 20.8291 9.53141 20.8997 9.48277C20.9703 9.43412 21.0191 9.35974 21.0355 9.27557C21.0519 9.1914 21.0347 9.10415 20.9876 9.03252C20.5363 8.37582 20.3349 7.57932 20.4199 6.78707C20.5049 5.99482 20.8705 5.25912 21.4508 4.71305C22.031 4.16698 22.7875 3.84658 23.5835 3.80981C24.3794 3.77304 25.1623 4.02231 25.7904 4.51256C26.4185 5.0028 26.8505 5.70165 27.0081 6.48269C27.1658 7.26373 27.0387 8.07541 26.6499 8.77091C26.2611 9.46641 25.6363 9.99982 24.8884 10.2747C24.1405 10.5495 23.3189 10.5476 22.5723 10.2694C22.513 10.2467 22.4484 10.2416 22.3863 10.2546C22.3241 10.2677 22.2671 10.2984 22.2219 10.343L22.2215 10.3434C22.1827 10.3823 22.1542 10.4302 22.1387 10.4829C22.1232 10.5356 22.1212 10.5914 22.1329 10.6451C22.1445 10.6988 22.1695 10.7487 22.2054 10.7902C22.2414 10.8317 22.2872 10.8636 22.3386 10.8828C22.8914 11.0893 23.4831 11.1706 24.0712 11.121C24.6592 11.0713 25.2289 10.8919 25.7393 10.5957C26.2496 10.2994 26.6879 9.89368 27.0227 9.40768C27.3574 8.92168 27.5802 8.36751 27.675 7.78506C27.7699 7.20262 27.7344 6.60639 27.5711 6.0393C27.4079 5.47222 27.1209 4.94839 26.7309 4.50552C26.3409 4.06266 25.8575 3.71177 25.3156 3.47814C24.7738 3.2445 24.1868 3.13387 23.597 3.15432Z"
          fill="#54C3D6"
        />
      </svg>
    </SvgIconWrapper>
  );
}
