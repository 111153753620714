import styled, { css } from "styled-components";

import { mobile } from "@sellernote/_shared/src/styles/functions";

const navigationButton = styled.button`
  display: none;
  width: auto;

  ${mobile(css`
    display: block;
    background: none;
    border: none;
  `)}
`;

export default {
  navigationButton,
};
